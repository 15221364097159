:root {
    --wrapper: 90%;
    --headerHeight: 120px;
    --white: #ffffff;
    --accent: #BAA384;
    --blue: #0640FF;
    --accent-transparent: #BAA384;
    --dark: #263977;
    --light: #c7d4ff;
    --footer-bg: rgba(245,221,81,1);
    --dark-bg: #2C2C2C;
    --dark-bg-transparent: #2C2C2C;
    --gradient-dark-bg: linear-gradient(116.23deg, #1d2337 5.44%, #193696 123.05%);
    --dark-blue-bg: #1a2859;
    --anchor: #294086;
    --radm: 8px
}

@media (max-width: 1200px) {
    :root {
        --wrapper: calc(100vw - 64px)
    }
}

@media (max-width: 900px) {
    :root {
        --wrapper: calc(100vw - 32px);
        --headerHeight: 100px
    }
}

*, ::after, ::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box
}

html {
    line-height: 1.15;
    -webkit-text-size-adjust: 100%
}

a {
    background-color: transparent;
    text-decoration: none
}

b, strong {
    font-weight: bolder
}

img {
    border-style: none
}

button, input, optgroup, select, textarea {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0
}

button, input {
    overflow: visible
}

button, select {
    text-transform: none
}

[type=button], [type=reset], [type=submit], button {
    -webkit-appearance: button
}

[type=button]::-moz-focus-inner, [type=reset]::-moz-focus-inner, [type=submit]::-moz-focus-inner, button::-moz-focus-inner {
    border-style: none;
    padding: 0
}

[type=button]:-moz-focusring, [type=reset]:-moz-focusring, [type=submit]:-moz-focusring, button:-moz-focusring {
    outline: 1px dotted ButtonText
}

[type=checkbox], [type=radio] {
    box-sizing: border-box;
    padding: 0
}

[type=number]::-webkit-inner-spin-button, [type=number]::-webkit-outer-spin-button {
    height: auto
}

[type=search] {
    -webkit-appearance: textfield;
    outline-offset: -2px
}

[type=search]::-webkit-search-decoration {
    -webkit-appearance: none
}

::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit
}

[hidden] {
    display: none
}

ul {
    list-style: none
}

li {
    display: flex;
    flex-direction: column
}

a {
    color: inherit
}

b {
    font-weight: 700
}

img, picture {
    display: block;
    -o-object-fit: contain;
    object-fit: contain;
    width: auto;
    height: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none
}

#info-section {
    padding-top: 0px;
}

#switch-section {
    position: absolute;
    margin-top: -425px
}

html {
    height: 100%;
    font-family: Poppins, sans-serif;
    font-weight: 400;
    scroll-behavior: smooth
}

body {
    height: 100%;
    overflow-x: hidden;
    font-size: 16px;
    line-height: 175%;
    color: var(--light);
    background-color: var(--white)
}

.body.burger-active {
    overflow-y: hidden
}

.wrapper {
    width: 100%;
    max-width: var(--wrapper);
    margin: 0 auto
}

h1, h2, h3, h4, h5, h6 {
    font-family: Mute, sans-serif;
    font-weight: 400
}

h1, h2 {
    font-size: 80px;
    line-height: .95;
    vertical-align: middle
}

@media (max-width: 1200px) {
    h1, h2 {
        font-size: 72px;
        line-height: .8
    }
}

@media (max-width: 900px) {
    h1, h2 {
        font-size: 48px
    }
}

h2.title {
    margin-top: 18px
}

@media (max-width: 900px) {
    h2.title {
        margin-top: 14px
    }
}

.text {
    font-size: 20px;
    line-height: 1.6;
    font-weight: 300
}

@media (max-width: 1200px) {
    .text {
        font-size: 16px;
        line-height: 28px
    }
}

@media (max-width: 900px) {
    .text {
        text-align: center
    }
}

.text--accent {
    font-weight: 600
}

.text--dark {
    color: var(--dark)
}

.text--light {
    color: var(--light)
}

.text--l {
    font-size: 32px;
    line-height: 1.5
}

@media (max-width: 1200px) {
    .text--l {
        font-size: 24px
    }
}

@media (max-width: 900px) {
    .text--l {
        font-size: 20px;
        line-height: 32px
    }
}

.text--content {
    font-size: 16px;
    line-height: 1.75
}

.text--thin {
    font-weight: 300
}

.text--color {
    color: var(--accent)
}

.anchor {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 50px;
    width: 30px;
    height: 30px;
    padding: 6px;
    color: var(--anchor);
    transition: all .25s ease
}

.anchor:hover {
    color: var(--accent)
}

.anchor__image {
    height: 100%;
    width: auto;
    max-width: 100%;
    -o-object-fit: contain;
    object-fit: contain
}

.header {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--headerHeight);
    background-color: var(--dark-bg);
    font-size: 16px;
    line-height: 1.5;
    color: var(--white);
    z-index: 100
}

.hidden-title {
    font-size: 0;
    position: absolute;
    line-height: 0;
    visibility: hidden
}

.header__nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

@media (max-width: 900px) {
    .header__nav {
        padding: 16px 0
    }
}

.header__button {
    display: none;
    position: absolute;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    align-self: flex-start;
    right: 14px;
    width: 28px;
    height: 22px;
    padding: 4px;
    z-index: 25
}

@media (max-width: 900px) {
    .header__button {
        display: inline-flex;
        align-self: center;
    }
}

.logo {
    display: inline-flex;
    height: 70%
}

.logo__image.mute-logo--footer {
    height: 100%;
    width: auto
}

.logo__image {
    display: inline-flex;
    width: 100%;
    height: auto;
    transition: all .25s ease
}

.logo--header {
    position: absolute;
    width: 500px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    will-change: transform;
    transform-origin: 0 50%;
    transform: translate(calc((var(--wrapper) / 2) - 50%), calc(50vh - 75%));
    filter: drop-shadow(0 36px 44px #0d111d);
    transition: all .25s ease, transform .75s ease
}

@media (max-width: 900px) {
    .logo--header {
        width: 432px;
        transform: translate(calc((var(--wrapper) / 2) - 50%), calc(50vh - 100%))
    }
}

@media (max-width: 900px) {
    .logo--header {
        width: 248px;
        transform: translate(calc((var(--wrapper) / 2) - 50%), calc(50vh - 100%))
    }
}

.logo--header.showed {
    transform: translate(0, 0) scale(.2352);
    filter: drop-shadow(0 0 0 #0d111d)
}

@media (max-width: 900px) {
    .logo--header.showed .mute-logo__word {
        fill: var(--accent)
    }
}

.header__button-line {
    width: 20px;
    height: 2px;
    border-radius: 1px;
    background-color: var(--white);
    transition: all .25s ease;
    pointer-events: none
}

.body.burger-active .menu.header__menu {
    transform: translateX(0);
    box-shadow: 0 -25vw 0 100vw rgba(0, 0, 0, .5);
}

.menu.header__menu__right {
    margin-right: 0px;
    margin-left: auto;
    width: 100%;
    padding-left: 0px;
}

.header_socials {
  flex-direction: row;
}

.body.burger-active .header__button-line--top {
    transform: translate(0, 6px) rotate(45deg)
}

.body.burger-active .header__button-line--mid {
    transform: translate(-20px);
    opacity: 0
}

.body.burger-active .header__button-line--bottom {
    transform: translate(0, -6px) rotate(-45deg)
}

@media (max-width: 900px) {
    .menu.header__menu {
        display: grid;
        position: fixed;
        grid-auto-flow: row;
        grid-row-gap: 26px;
        justify-content: center;
        align-items: flex-start;
        grid-auto-rows: 150px;
        width: 100%;
        top: 0;
        right: 0;
        left: auto;
        margin: 0;
        padding: 96px 56px 18px 44px;
        width: 62.5%;
        height: 100vh;
        background-color: var(--dark-bg);
        transform: translateX(100%);
        box-shadow: 0 -37.5vw 0 100vw rgba(11, 17, 38, 0);
        transition: all .25s ease
    }
}

.menu {
    display: inline-grid;
    margin-bottom: 0px;
    grid-auto-flow: column;
    grid-column-gap: 20px;
    width: 90%;
    align-items: center;
    margin-top: 0px;
    height: 100%;
    justify-content: space-around;
}

.menu__link {
    padding: 4px 10px;
    transition: all .25s ease
}

.menu__link:hover {
    color: var(--accent)
}

.socials__link:hover {
    fill: var(--accent)
}

.title {
    color: var(--accent)
}

@media (max-width: 900px) {
    .title {
        text-align: center
    }
}

.link {
    display: inline-grid;
    grid-auto-flow: column;
    grid-column-gap: 4px;
    align-items: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-weight: 600;
    color: var(--accent);
    text-decoration: underline;
    transition: all .25s ease
}

.link:hover {
    color: var(--white)
}

.link__arrow {
    width: 12px;
    height: 12px
}

.buttons {
    display: flex;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row
}

@media (max-width: 1200px) {
    .buttons {
        flex-direction: column;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content
    }
}

@media (max-width: 900px) {
    .buttons {
        width: 100%
    }
}

.buttons__row {
    display: flex;
    align-items: center;
    justify-content: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content
}

@media (max-width: 1200px) {
    .buttons__row {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content
    }
}

@media (max-width: 900px) {
    .buttons__row {
        flex-direction: column;
        width: 100%
    }
}

.buttons__row + .buttons__row {
    margin-left: 24px
}

@media (max-width: 1200px) {
    .buttons__row + .buttons__row {
        margin-left: 0;
        margin-top: 24px
    }
}

@media (max-width: 900px) {
    .buttons__row + .buttons__row {
        margin-top: 16px
    }
}

.button {
    display: inline-flex;
    position: relative;
    justify-content: center;
    align-items: center;
    justify-self: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    min-width: 180px;
    height: 56px;
    padding: 12px 32px;
    border-radius: var(--radm);
    font-size: 18px;
    line-height: 1.5;
    font-weight: 600;
    text-align: center;
    white-space: nowrap;
    color: var(--dark);
    transition: all .25s ease;
    z-index: 1
}

.button_app {
    height: 100%;
    padding-top: 6px;
    padding-bottom: 6px;
    text-align: center;
    width: 100px;
    min-width: 100px;
    background: var(--accent-transparent);
    font-size: 14px;
    font-weight: 600;
    color: var(--accent);
    border: 2px solid var(--accent);
    border-radius: var(--radm);
}

.button_app:hover {
    color: white;
}

@media (max-width: 900px) {
    .button_app {
      width: 100%;
      height: 38px;
    }
}




.button:before {
    content: "";
    position: absolute;
    width: calc(100% + 2px);
    height: 100%;
    border-radius: var(--radm);
    background: linear-gradient(to right, var(--dark-bg) 40%, var(--accent), var(--accent), var(--blue));
    background-size: 300% 100%;
    background-position: 100% 50%;
    -webkit-mask-size: contain, contain, calc(100% - 14px) 100%;
    mask-size: contain, contain, calc(100% - 14px) 100%;
    -webkit-mask-position: left, right, center;
    mask-position: left, right, center;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    transition: all .25s ease, opacity 0s ease;
    pointer-events: none;
    z-index: 0;
    opacity: .5
}

.button:hover {
    background-color: var(--dark-bg);
    color: var(--accent)
}

.button:hover:before {
    opacity: 1
}

.button:active, .button:focus {
    color: var(--white);
    background-color: var(--dark-bg)
}

.button:active:before, .button:focus:before {
    opacity: 1
}

@media (max-width: 900px) {
    .button {
        width: 100%
    }
}

.button + .button {
    margin-left: 24px
}

@media (max-width: 900px) {
    .button + .button {
        margin-left: 0;
        margin-top: 16px
    }
}

.button--accent {
    background-color: var(--accent);
    border: 2px solid var(--accent);
    color: var(--white)
}

.button--accent:before {
    display: none
}

.button--accent:hover {
    background-color: var(--dark-bg);
    border-color: var(--dark-bg);
    color: var(--accent)
}

.button--accent:active, .button--accent:focus {
    background-color: var(--dark-bg);
    border-color: var(--dark-bg);
    color: var(--white)
}

.section--buttons-light .button:not(.button--accent) {
    color: var(--white)
}

.section--buttons-light .button:not(.button--accent):hover {
    color: var(--accent);
    background-image: linear-gradient(var(--dark-bg), var(--dark-bg) 2px, var(--white) 2px, var(--white) calc(100% - 2px), var(--dark-bg) calc(100% - 2px), var(--dark-bg) 100%)
}

.section--buttons-light .button:not(.button--accent):hover:before {
    background-position: 80% 50%;
    opacity: 1
}

.section--buttons-light .button:not(.button--accent):active, .section--buttons-light .button:not(.button--accent):focus {
    color: var(--dark-bg);
    background-color: var(--white)
}

.section--buttons-light .button:not(.button--accent):active:before, .section--buttons-light .button:not(.button--accent):focus:before {
    background-position: 0 50%;
    opacity: 1
}

.section--buttons-light .button--accent {
    color: var(--white);
    background-color: var(--accent)
}

.section--buttons-light .button--accent:hover {
    color: var(--accent);
    background-color: var(--white);
    border-color: var(--white)
}

.section--buttons-light .button--accent:active, .section--buttons-light .button--accent:focus {
    color: var(--dark-bg);
    background-color: var(--white);
    border-color: var(--white)
}

.main {
    width: 100%;
    overflow-x: hidden
}

@media (max-width: 1200px) {
    .main {
        overflow-y: visible
    }
}

.section {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding: 104px 0
}

@media (max-width: 1200px) {
    .section {
        padding: 78px 0
    }
}

@media (max-width: 900px) {
    .section {
        padding: 64px 0
    }
}

.section--dark {
    background-color: var(--dark-bg)
}

.section--light {
    background-color: var(--white);
    color: var(--dark)
}

.section__content {
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content
}

.section--hero {
    min-height: 100vh;
    justify-content: flex-end;
    margin-top: var(--headerHeight);
    padding: 168px 0 100px;
    background-size: cover;
    background-position: top center;
}

@keyframes growAndShrink {
    0% {
      background-position: 45% 0;
    }
    100% {
      background-position: 55% 0;
    }
}

@media (max-width: 1200px) {
    .section--hero {
        min-height: 100vh;
        padding: 168px 0 100px
    }
}

@media (max-width: 900px) {
    .section--hero {
        min-height: 100vh;
        margin-top: 0;
        padding: 168px 0 100px
    }
}

.section--hero .content {
    display: grid;
    grid-template-columns:1fr 2.3fr;
    grid-column-gap: 62px;
    margin-top: auto
}

@media (max-width: 1200px) {
    .section--hero .content {
        grid-template-rows:auto auto;
        grid-template-columns:1fr;
        grid-column-gap: 0;
        grid-row-gap: 48px;
        text-align: center
    }
}

@media (max-width: 900px) {
    .section--hero .content {
        grid-row-gap: 32px
    }
}

.section--hero .content.content--hero .content__text {
    grid-row-gap: 0
}

.section--hero .content.content--hero .content__text .text + .text {
    margin-top: 32px
}

@media (max-width: 900px) {
    .section--hero .content.content--hero .content__text .text + .text {
        margin-top: 22px
    }
}

@media (max-width: 1200px) {
    .section--hero .content.content--hero .content__text {
        padding: 0 62px
    }
}

@media (max-width: 900px) {
    .section--hero .content.content--hero .content__text {
        padding: 0
    }
}

.section--hero .text {
    color: var(--light);
    font-weight: 400
}

.section--hero .content--side .text {
    font-size: 36px;
    line-height: 48px;
    font-weight: 300;
    color: var(--white)
}

@media (max-width: 1200px) {
    .section--hero .content--side .text {
        font-size: 32px
    }
}

@media (max-width: 900px) {
    .section--hero .content--side .text {
        font-size: 20px;
        line-height: 32px
    }
}

.section--token {
    padding-top: 128px
}

@media (max-width: 1200px) {
    .section--token {
        padding-top: 86px
    }
}

@media (max-width: 900px) {
    .section--token {
        padding-top: 64px
    }
}

.section--token .section__content {
    align-items: center
}

.section--token .content {
    max-width: 800px
}

@media (max-width: 1200px) {
    .section--token .content {
        max-width: 646px
    }
}

.section--token .content__text {
    grid-row-gap: 14px
}

@media (max-width: 900px) {
    .section--token .content__text {
        grid-row-gap: 42px
    }
}

.section--token .title {
    margin-top: 10px
}

.section--token .buttons {
    margin-top: 78px
}

@media (max-width: 1200px) {
    .section--token .buttons .buttons__row {
        justify-content: space-evenly
    }
}

@media (max-width: 900px) {
    .section--token .buttons {
        margin-top: 34px
    }
}

.section--switch {
    padding: 128px 0 220px
}

@media (max-width: 1200px) {
    .section--switch {
        padding: 102px 0 140px
    }
}

@media (max-width: 900px) {
    .section--switch {
        padding: 64px 0 60px
    }
}

.section--switch .content__text {
    max-width: 508px
}

@media (max-width: 1200px) {
    .section--switch .content__text {
        max-width: 368px
    }
}

@media (max-width: 900px) {
    .section--switch .content__text {
        grid-row-gap: 26px
    }

    .section--switch .content__text .text {
        margin-top: 216px
    }
}

.section--switch .section__image {
    position: absolute;
    justify-self: center;
    width: 100%;
    max-width: 720px;
    height: auto;
    top: 50%;
    right: calc((100vh - var(--wrapper)) / 2);
    transform: translate(30%, -38%)
}

@media (max-width: 1200px) {
    .section--switch .section__image {
        max-width: 460px;
        right: 0;
        transform: translate(37%, -23%)
    }
}

@media (max-width: 900px) {
    .section--switch .section__image {
        right: 50%;
        max-width: 280px;
        max-height: 164px;
        transform: translate(50%, -50%)
    }
}

.section--voice {
    background: var(--gradient-dark-bg)
}

.section--voice .content {
    grid-column-gap: 136px
}

@media (max-width: 1200px) {
    .section--voice .content {
        grid-column-gap: 68px
    }
}

.section--voice .content__text {
    max-width: 592px
}

.section--voice .text--l {
    color: var(--white)
}

.section--voice .content__image {
    max-height: 252px;
    margin-top: 22px
}

@media (max-width: 1200px) {
    .section--voice .content__image {
        max-height: 198px;
        margin-top: 48px
    }
}

@media (max-width: 900px) {
    .section--voice .content__image {
        max-height: 130px;
        margin-top: 0;
        order: -1
    }
}

.section--voice .buttons {
    margin-top: 56px;
    width: 100%
}

@media (max-width: 1200px) {
    .section--voice .buttons {
        width: 100%;
        justify-content: center;
        align-self: center;
        align-items: center
    }
}

@media (max-width: 900px) {
    .section--voice .buttons {
        margin-top: 40px
    }
}

.section--voice .button {
    min-width: 266px
}

@media (max-width: 900px) {
    .section--voice .button {
        min-width: 0
    }
}

.section--voice .buttons .button:nth-child(2n+3) {
    grid-column: 1/span 2;
    justify-self: center
}

.section--voice .cards {
    margin-top: 72px;
    padding-left: 0px;
}

@media (max-width: 1200px) {
    .section--voice .cards {
        margin-top: 54px
    }
}

@media (max-width: 900px) {
    .section--voice .cards {
        margin-top: 40px;
        padding-left: 0px;
    }
}

.section--posts .buttons {
    margin-top: 112px;
    align-self: center
}

@media (max-width: 1200px) {
    .section--posts .buttons {
        margin-top: 56px;
        margin-bottom: 10px
    }
}

.content {
    display: grid;
    grid-auto-flow: column;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    grid-column-gap: 72px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content
}

@media (max-width: 1200px) {
    .content {
        grid-column-gap: 48px
    }
}

@media (max-width: 900px) {
    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
}

.content__text {
    display: grid;
    grid-row-gap: 22px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content
}

@media (max-width: 900px) {
    .content__text {
        grid-row-gap: 34px;
        justify-items: center
    }
}

.content--center {
    align-self: center
}

.content__image {
    max-height: 200px
}

@media (max-width: 1200px) {
    .content__image {
        max-height: 168px
    }
}

@media (max-width: 900px) {
    .content__image {
        max-height: 108px;
        margin-bottom: 40px
    }
}

.underline {
  text-decoration: underline;
}
.subinfo {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 12px 20px;
    font-size: 16px;
    line-height: 18px;
    font-weight: 500;
    color: var(--accent);
    border-radius: var(--radm);
    background-color: var(--accent-transparent)
}

.cards {
    display: inline-grid;
    grid-auto-flow: column;
    grid-column-gap: 30px;
    grid-row-gap: 32px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding-top: 200px;
    padding-right: 32px;
}

@media (max-width: 1200px) {
    .cards {
        grid-auto-flow: row;
        width: 100%
    }
}

@media (max-width: 900px) {
    .cards {
        grid-row-gap: 24px
    }
}

.card {
    display: inline-grid;
    justify-items: center;
    justify-content: center;
    grid-row-gap: 26px;
    max-width: 540px;
    padding: 48px 48px 56px;
    border-radius: var(--radm);
    border: 1px solid var(--dark-bg-transparent);
    color: var(--white);
    text-align: center;
    background-color: var(--dark-bg-transparent);
    transition: all .25s ease
}

.card:hover {
    border-color: var(--accent)
}

@media (max-width: 1200px) {
    .card {
        display: grid;
        width: 100%;
        max-width: none;
        padding: 48px 130px 56px;
        grid-row-gap: 28px
    }
}

@media (max-width: 900px) {
    .card {
        padding: 40px 16px;
        grid-row-gap: 36px
    }
}

.card__quote {
    margin-top: 8px;
    max-height: 76px
}

.card__title {
    font-size: 24px;
    line-height: 40px;
    font-weight: 600;
    font-family: Poppins, sans-serif
}

@media (max-width: 900px) {
    .card__title {
        font-size: 20px;
        line-height: 32px
    }
}

.blog {
    display: grid;
    grid-template-columns:1fr 1fr;
    grid-template-rows:1fr 1fr 1fr;
    grid-row-gap: 55px;
    grid-column-gap: 30px;
    margin-top: 80px
}

@media (max-width: 1200px) {
    .blog {
        grid-template-columns:1fr;
        grid-template-rows:auto;
        grid-auto-rows: auto;
        grid-row-gap: 32px;
        margin-top: 48px;
        padding-left: 0px;
    }
}

@media (max-width: 900px) {
    .blog {
        grid-row-gap: 48px
    }
}

.article {
    display: inline-grid;
    position: relative;
    grid-template-columns:1fr 1fr;
    grid-template-rows:fit-content(40%) auto auto;
    grid-template-areas:"image title" "image content" "image link";
    grid-row-gap: 8px;
    grid-column-gap: 24px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    max-height: 174px;
    font-family: Poppins, sans-serif
}

@media (max-width: 1200px) {
    .article {
        grid-template-rows:58px auto 24px;
        grid-template-columns:1fr 1.6fr;
        grid-row-gap: 8px;
        max-height: unset
    }
}

@media (max-width: 900px) {
    .article {
        grid-template-rows:auto auto auto 20px;
        grid-template-columns:1fr 1fr;
        grid-template-areas:"image image" "title title" "content content" "date link";
        grid-row-gap: 12px
    }
}

.article__image {
    grid-area: image;
    width: 100%;
    max-height: 174px;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: var(--radm);
    overflow: hidden
}

@media (max-width: 900px) {
    .article__image {
        max-height: 192px
    }
}

.article__title {
    grid-area: title;
    font-size: 20px;
    line-height: 1.6;
    font-weight: 500;
    color: var(--white);
    font-family: Poppins, sans-serif;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content
}

@media (max-width: 900px) {
    .article__title {
        margin-top: 12px
    }
}

.article__text {
    grid-area: content;
    font-size: 14px;
    line-height: 24px;
    font-weight: 300;
    color: var(--light);
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-height: 48px;
    overflow: hidden
}

@media (max-width: 1200px) {
    .article__text {
        -webkit-line-clamp: 2;
        max-height: 72px
    }
}

@media (max-width: 900px) {
    .article__text {
        font-size: 16px;
        line-height: 28px;
        -webkit-line-clamp: 4;
        max-height: 112px
    }
}

.article__date {
    display: none;
    grid-area: date;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color: var(--light)
}

@media (max-width: 900px) {
    .article__date {
        display: inline-flex;
        font-size: 14px;
        line-height: 20px
    }
}

.article__link {
    justify-self: end;
    grid-area: link;
    font-size: 14px;
    line-height: 1;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content
}

@media (max-width: 1200px) {
    .article__link {
        font-size: 16px
    }
}

@media (max-width: 900px) {
    .article__link {
        font-size: 14px
    }
}

.article:first-child {
    grid-template-rows:auto auto auto 20px;
    grid-template-columns:1fr 1fr;
    grid-template-areas:"image image" "title title" "content content" "date link";
    grid-area: 1/1/4/2;
    grid-row-gap: 12px;
    max-height: 100%
}

@media (max-width: 1200px) {
    .article:first-child {
        grid-area: unset;
        margin-bottom: 4px
    }
}

.article:first-child .article__image {
    max-height: 312px
}

@media (max-width: 1200px) {
    .article:first-child .article__image {
        max-height: 320px
    }
}

@media (max-width: 900px) {
    .article:first-child .article__image {
        max-height: 192px
    }
}

.article:first-child .article__title {
    font-size: 32px;
    line-height: 1.25;
    margin-top: 20px
}

@media (max-width: 900px) {
    .article:first-child .article__title {
        font-size: 20px;
        line-height: 32px;
        margin-top: 12px
    }
}

.article:first-child .article__text {
    margin-top: 4px;
    font-size: 16px;
    line-height: 28px;
    -webkit-line-clamp: 4;
    max-height: 112px
}

@media (max-width: 1200px) {
    .article:first-child .article__text {
        margin-top: 0;
        line-clamp: 3;
        -webkit-line-clamp: 3;
        max-height: 84px
    }
}

@media (max-width: 900px) {
    .article:first-child .article__text {
        line-clamp: 4;
        -webkit-line-clamp: 4;
        max-height: 112px
    }
}

.article:first-child .article__date {
    display: inline-flex
}

@media (max-width: 900px) {
    .article:first-child .article__date {
        font-size: 14px
    }
}

.article:first-child .article__link {
    font-size: 16px
}

@media (max-width: 900px) {
    .article:first-child .article__link {
        font-size: 14px
    }
}

.footer {
    background-color: var(--footer-bg);
    height: 130px;
    color: var(--white)
}

@media (max-width: 900px) {
    .footer {
        height: auto
    }
}

.footer__nav {
    display: grid;
    grid-template-columns:1fr 1fr;
    grid-template-rows:32px 22px;
    grid-row-gap: 30px;
    justify-content: space-between;
    align-items: center;
    padding: 24px 0 20px
}

@media (max-width: 900px) {
    .footer__nav {
        grid-template-columns:1fr;
        grid-template-rows:32px auto;
        padding: 24px 0 16px
    }
}

.footer__menu {
    justify-self: end
}

@media (max-width: 900px) {
    .footer__menu {
        width: 100%;
        grid-auto-flow: row;
        grid-column-gap: 0;
        grid-row-gap: 8px;
        justify-items: center;
        padding-left: 0px;
    }
}

.footer .logo {
    height: 20px
}

@media (max-width: 900px) {
    .footer .logo {
        justify-content: center
    }
}

.footer__side-link {
    opacity: .5;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 1px
}

@media (max-width: 900px) {
    .footer__side-link {
        text-align: center
    }
}

svg {
    display: inline-flex;
    height: 100%;
    width: auto;
    -o-object-fit: contain;
    object-fit: contain
}

.logo--header .mute-logo__dott, .logo--header .mute-logo__word {
    transition: all .25s ease
}

.logo--header:hover .mute-logo__word {
    fill: var(--accent)
}

.logo--header:hover .mute-logo__dott {
    fill: var(--white)
}

.footer .logo .mute-logo__dott, .footer .logo .mute-logo__word {
    transition: all .25s ease
}

.footer .logo:hover .mute-logo__word {
    fill: var(--accent)
}

.footer .logo:hover .mute-logo__dott {
    fill: var(--accent)
}

.btn-wrapper {
    text-align: center;
    position: absolute;
    bottom: 0px;
    margin: 0 auto;
    width: 100%;
}

.svg-mod {
    width: 50%;
    position: relative;
}

.section--dock {
    background-color: #080A11
}

.col {
    background-color: #0C101C
}

.partner__group {
    display: grid;
    grid-auto-flow: column;
    grid-row-gap: 22px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content
}

.partner__image {
    width: 200px;
    padding: 20px;
}

@media (max-width: 775px) {
    .partner__group {
      grid-auto-flow: row;
    }
}

.partner_title {
    color: white;
    font-size: 35px;
}

.section--partner {
    padding-top: 112px;
    padding-bottom: 0px;
}

.menu__item {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 0px;
  border-bottom: 5px solid rgba(0,0,0,0);
}

.menu__item:hover {
  border-bottom: 5px solid #BAA384;
  color: #BAA384;
  fill: #BAA384;
}


.header__menu__wrapper .w--current {
  border-bottom: 5px solid #BAA384;
  color: #BAA384;
  fill: #BAA384;
}

.header__menu__wrapper .w--current .header_image_hover {
  display: inline;
}

.header__menu__wrapper .w--current .header_image {
  display: none;
}

.header_image {
  width: 60px;
  height: auto;
}
.header_image_hover {
  width: 60px;
  height: auto;
}

.header__menu__wrapper {
  height: 100%;
  flex-direction: row;
  display: flex;
}

@media (max-width: 900px) {
  .header__menu__wrapper {
    height: auto;
    flex-direction: column;
    display: flex;
  }

  .menu__filler {
    display: none;
  }
}

.header_image_hover {
  display: none;
}

.header__item:hover .header_image_hover {
  display: inline;
}

.header__item:hover .header_image {
  display: none;
}
