body {
  font-family: 'Open Sans', sans-serif;
  color: #1a1b1f;
  font-size: 16px;
  line-height: 28px;
  font-weight: 400;
}

h1 {
  margin-top: 20px;
  margin-bottom: 15px;
  font-size: 44px;
  line-height: 62px;
  font-weight: 400;
}

h2 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 36px;
  line-height: 50px;
  font-weight: 400;
}

h3 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 30px;
  line-height: 46px;
  font-weight: 400;
}

h4 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 24px;
  line-height: 38px;
  font-weight: 400;
}

h5 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 20px;
  line-height: 34px;
  font-weight: 500;
}

h6 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 28px;
  font-weight: 500;
}

p {
  margin-bottom: 10px;
  font-family: Merriweather, serif;
  font-weight: 300;
}

a {
  display: block;
  -webkit-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
  color: #7be5c4;
  text-decoration: underline;
}

a:hover {
  color: #32343a;
}

a:active {
  color: #43464d;
}

ul {
  margin-top: 20px;
  margin-bottom: 20px;
  padding-left: 40px;
  list-style-type: disc;
}

li {
  margin-bottom: 10px;
}

img {
  display: block;
}

label {
  margin-bottom: 10px;
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}

blockquote {
  margin-top: 25px;
  margin-bottom: 25px;
  padding: 15px 30px;
  border-left: 5px solid #e2e2e2;
  font-size: 20px;
  line-height: 34px;
}

figure {
  margin-top: 25px;
  padding-bottom: 20px;
}

figcaption {
  margin-top: 5px;
  opacity: 0.6;
  font-size: 14px;
  line-height: 26px;
  text-align: center;
}

.heading-jumbo-small {
  margin-top: 10px;
  margin-bottom: 15px;
  font-size: 36px;
  line-height: 50px;
  font-weight: 400;
  text-transform: none;
}

.styleguide-block {
  display: block;
  margin-top: 80px;
  margin-bottom: 80px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: left;
}

.heading-jumbo-tiny {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 32px;
  font-weight: 500;
  text-transform: uppercase;
}

.rich-text {
  width: 70%;
  margin-right: auto;
  margin-bottom: 100px;
  margin-left: auto;
}

.rich-text p {
  margin-top: 15px;
  margin-bottom: 25px;
  opacity: 0.6;
}

.container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 80%;
  height: 90%;
  max-height: 9%;
  max-width: 80%;
  margin: 80vh auto 0vh;
  padding-bottom: 0vh;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.styleguide-content-wrap {
  text-align: center;
}

.paragraph-small {
  font-size: 14px;
  line-height: 26px;
}

.styleguide-header-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 460px;
  padding: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #1a1b1f;
  color: #fff;
  text-align: center;
}

.styleguide-button-wrap {
  margin-top: 10px;
  margin-bottom: 10px;
}

.heading-jumbo {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 64px;
  line-height: 80px;
  text-transform: none;
}

.paragraph-tiny {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  max-height: 100%;
  padding-right: 15px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: rgba(235, 115, 46,1) !important;
  font-size: 12px;
  line-height: 20px;
}

.paragraph-tiny.cc-paragraph-tiny-light {
  opacity: 0.7;
}

.label {
  margin-bottom: 10px;
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.label.cc-styleguide-label {
  margin-bottom: 25px;
}

.label.cc-speaking-label {
  margin-top: 20px;
  margin-bottom: 10px;
}

.label.cc-about-light {
  opacity: 0.6;
}

.paragraph-light {
  opacity: 0.6;
}

.paragraph-light.cc-position-name {
  margin-bottom: 5px;
}

.first-section {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: auto;
  max-height: 130vh;
  margin-right: 0px;
  margin-left: 0px;
  padding-top: 0%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.first-section.cc-contact {
  padding-right: 80px;
  padding-left: 80px;
  background-color: #f4f4f4;
}

.button {
  padding: 12px 25px;
  border-radius: 0px;
  background-color: #1a1b1f;
  -webkit-transition: background-color 400ms ease, opacity 400ms ease, color 400ms ease;
  transition: background-color 400ms ease, opacity 400ms ease, color 400ms ease;
  color: #fff;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 2px;
  text-decoration: none;
  text-transform: uppercase;
}

.button:hover {
  background-color: #32343a;
  color: #fff;
}

.button:active {
  background-color: #43464d;
}

.button.cc-jumbo-button {
  padding: 16px 35px;
  font-size: 14px;
  line-height: 26px;
}

.button.cc-white-button {
  padding: 16px 35px;
  background-color: #fff;
  color: #202020;
  font-size: 14px;
  line-height: 26px;
}

.button.cc-white-button:hover {
  background-color: hsla(0, 0%, 100%, 0.8);
}

.button.cc-white-button:active {
  background-color: hsla(0, 0%, 100%, 0.9);
}

.paragraph-bigger {
  margin-bottom: 10px;
  opacity: 1;
  font-size: 20px;
  line-height: 34px;
  font-weight: 400;
}

.paragraph-bigger.cc-bigger-light {
  opacity: 0.6;
}

.divider {
  height: 1px;
  background-color: #eee;
}

.logo-link {
  z-index: 1;
}

.logo-link:hover {
  opacity: 0.8;
}

.logo-link:active {
  opacity: 0.7;
}

.logo-link.w--current {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10%;
  padding-right: 0%;
  padding-left: 0%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.navigation-item {
  padding-top: 9px;
  padding-bottom: 9px;
  opacity: 0.6;
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.navigation-item:hover {
  opacity: 0.9;
}

.navigation-item:active {
  opacity: 0.8;
}

.navigation-item.w--current {
  opacity: 1;
  color: #1a1b1f;
  font-weight: 600;
}

.navigation-item.w--current:hover {
  opacity: 0.8;
  color: #32343a;
}

.navigation-item.w--current:active {
  opacity: 0.7;
  color: #32343a;
}

.navigation-items {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.navigation {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 30px 50px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;
}

.logo-image {
  display: block;
}

.navigation-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 0px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.intro-wrap {
  margin-top: 100px;
  margin-bottom: 140px;
}

.name-text {
  font-size: 20px;
  line-height: 34px;
  font-weight: 400;
}

.position-name-text {
  margin-bottom: 10px;
  font-size: 20px;
  line-height: 34px;
  font-weight: 400;
  text-transform: none;
}

.work-description {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-bottom: 60px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.work-experience-grid {
  margin-bottom: 140px;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  grid-template-areas: ". . . .";
  -ms-grid-columns: 1fr 30px 1fr 30px 1fr 30px 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.works-grid {
  margin-bottom: 80px;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  grid-template-areas: ". . ."
    ". . .";
  -ms-grid-columns: 1.5fr 30px 1fr 30px 1.5fr;
  grid-template-columns: 1.5fr 1fr 1.5fr;
  -ms-grid-rows: auto 30px auto;
  grid-template-rows: auto auto;
}

.carrer-headline-wrap {
  width: 70%;
  margin-bottom: 50px;
}

.work-image {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 460px;
  margin-bottom: 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  background-color: #f4f4f4;
  background-position: 50% 50%;
  background-size: cover;
  text-align: center;
  text-decoration: none;
}

.work-image:hover {
  opacity: 0.8;
}

.work-image:active {
  opacity: 0.7;
}

.project-name-link {
  margin-bottom: 5px;
  font-size: 20px;
  line-height: 34px;
  font-weight: 400;
  text-decoration: none;
}

.project-name-link:hover {
  opacity: 0.8;
}

.project-name-link:active {
  opacity: 0.7;
}

.text-field {
  margin-bottom: 18px;
  padding: 21px 20px;
  border: 1px solid #e4e4e4;
  border-radius: 0px;
  -webkit-transition: border-color 400ms ease;
  transition: border-color 400ms ease;
  font-size: 14px;
  line-height: 26px;
}

.text-field:hover {
  border-color: #e3e6eb;
}

.text-field:active {
  border-color: #43464d;
}

.text-field:focus {
  border-color: #43464d;
}

.text-field::-webkit-input-placeholder {
  color: rgba(50, 52, 58, 0.4);
}

.text-field:-ms-input-placeholder {
  color: rgba(50, 52, 58, 0.4);
}

.text-field::-ms-input-placeholder {
  color: rgba(50, 52, 58, 0.4);
}

.text-field::placeholder {
  color: rgba(50, 52, 58, 0.4);
}

.text-field.cc-textarea {
  height: 200px;
  padding-top: 12px;
}

.status-message {
  padding: 9px 30px;
  background-color: #202020;
  color: #fff;
  font-size: 14px;
  line-height: 26px;
  text-align: center;
}

.status-message.cc-success-message {
  background-color: #12b878;
}

.status-message.cc-error-message {
  background-color: #db4b68;
}

.contact {
  padding-top: 80px;
  padding-bottom: 90px;
}

.contact-headline {
  width: 70%;
  margin-bottom: 40px;
}

.contact-form-grid {
  grid-column-gap: 30px;
  grid-row-gap: 10px;
}

.contact-form-wrap {
  width: 70%;
}

.footer-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 60px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  background-color: transparent !important;
}

.webflow-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  opacity: 1;
  -webkit-transition: opacity 400ms ease;
  transition: opacity 400ms ease;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
}

.webflow-link:hover {
  opacity: 1;
}

.webflow-link:active {
  opacity: 0.8;
}

.webflow-logo-tiny {
  margin-top: -2px;
  margin-right: 8px;
}

.footer-links {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 0px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.footer-item {
  display: block;
  width: 50px;
  height: 50px;
  max-height: 50px;
  max-width: 50px;
  margin-right: 10px;
  margin-left: 10px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  background-image: url('../../../assets/1x/FOOTER/Asset 39.png') !important;
  background-position: 50% 50%;
  background-size: 45px;
  background-repeat: no-repeat;
  opacity: 1;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 1px;
  text-decoration: none;
  text-transform: uppercase;
}

.footer-item:hover {
  opacity: 0.8;
}

.footer-item:active {
  opacity: 0.8;
}

.about-intro-grid {
  margin-top: 100px;
  margin-bottom: 140px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 80px;
  grid-row-gap: 30px;
  grid-template-areas: ". .";
  -ms-grid-columns: 1fr 80px 2fr;
  grid-template-columns: 1fr 2fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.hi-there-heading {
  margin-top: 10px;
  margin-bottom: 20px;
}

.service-name-text {
  margin-bottom: 10px;
  opacity: 0.6;
  font-size: 30px;
  line-height: 46px;
}

.skillset-wrap {
  padding-right: 60px;
}

.reference-link {
  opacity: 0.6;
  font-size: 14px;
  line-height: 26px;
  text-decoration: none;
}

.reference-link:hover {
  opacity: 1;
}

.reference-link:active {
  opacity: 0.9;
}

.featured-item-wrap {
  margin-bottom: 25px;
}

.services-items-grid {
  padding-top: 10px;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  grid-template-areas: ". .";
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.skills-grid {
  margin-bottom: 140px;
  grid-column-gap: 80px;
  grid-row-gap: 30px;
  grid-template-areas: ". .";
  -ms-grid-columns: 1fr 80px 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.personal-features-grid {
  margin-bottom: 110px;
  grid-column-gap: 80px;
  grid-row-gap: 20px;
  grid-template-areas: ". ."
    ". .";
  -ms-grid-rows: auto 20px auto;
  grid-template-rows: auto auto;
}

.speaking-text {
  display: inline-block;
  margin-right: 8px;
}

.speaking-text.cc-past-speaking {
  opacity: 0.6;
}

.speaking-detail {
  display: inline-block;
  opacity: 0.6;
}

.upcoming-wrap {
  margin-bottom: 40px;
}

.social-media-heading {
  margin-bottom: 60px;
}

.social-media-grid {
  margin-bottom: 30px;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  -ms-grid-rows: auto 30px auto;
  grid-template-areas: ". . . ."
    ". . . .";
  -ms-grid-columns: 1fr 30px 1fr 30px 1fr 30px 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.project-overview-grid {
  margin-top: 120px;
  margin-bottom: 135px;
  grid-column-gap: 50px;
  grid-row-gap: 100px;
  grid-template-areas: ". . . ."
    ". . . .";
  -ms-grid-columns: 1fr 50px 1fr 50px 1fr 50px 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto 100px auto;
  grid-template-rows: auto auto;
}

.detail-header-image {
  width: 100%;
}

.project-description-grid {
  margin-top: 120px;
  margin-bottom: 120px;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  grid-template-areas: ". .";
  -ms-grid-columns: 1fr 30px 2.5fr;
  grid-template-columns: 1fr 2.5fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.detail-image {
  width: 100%;
  margin-bottom: 30px;
}

.email-section {
  width: 70%;
  margin: 140px auto 200px;
  text-align: center;
}

.email-link {
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 64px;
  line-height: 88px;
  font-weight: 400;
  text-decoration: none;
  text-transform: none;
}

.email-link:hover {
  opacity: 0.8;
}

.email-link:active {
  opacity: 0.7;
}

.utility-page-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100vw;
  height: 100vh;
  max-height: 100%;
  max-width: 100%;
  padding: 30px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fff;
  text-align: center;
}

._404-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  padding: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #1a1b1f;
}

._404-content-wrap {
  margin-bottom: 20px;
}

.protected-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 90px;
  padding-bottom: 100px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}

.protected-form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.protected-heading {
  margin-bottom: 30px;
}

.image {
  max-height: 50px;
  max-width: 50px;
}

.social_header_buttons {
  height: 50px;
  max-height: 50px;
  max-width: 50px;
  margin-right: 0px;
  margin-left: 20px;
}

.connect_button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 40px;
  max-height: 40px;
  margin-right: 0px;
  margin-left: 0px;
  padding: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 6px;
  background-color: rgba(0,0,0,0);
  border: 1px #E7DDA0 solid;
  color: #E7DDA0;
  font-size: 13px;
  width: 130px;
  text-align: center;
}

.connect_button:hover {
  background-color: #E7DDA0;
  color: rgba(245,221,81,1);
}

.feature-section-roadmap {
  height: auto;
  max-height: none;
  max-width: 100%;
  min-height: 0%;
  padding-top: 100px;
  padding-bottom: 100px;
}

.flex-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 80%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.feature-image-mask {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  height: 100%;
  max-height: 100%;
  max-width: 50%;
  margin-right: 0px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  text-align: right;
}

.first-section-image {
  width: 85%;
  max-width: 85%;
  margin-top: 0px;
  margin-bottom: 0px;
  opacity: 1;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: 50% 50%;
  object-position: 50% 50%;
}

.div-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  max-height: 100%;
  max-width: 50%;
  min-height: 100%;
  padding-left: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.the-story-image-mask {
  position: static;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 100%;
  margin-right: -20%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  text-align: right;
}

.section-heading {
  font-family: 'Open Sans', sans-serif;
  color: #fff;
  font-weight: 700;
}

.section-text {
  display: block;
  width: 100%;
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  margin-top: 0px;
  margin-bottom: auto;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  font-family: Merriweather, serif;
  color: #fff;
  font-size: 18px;
  font-weight: 300;
  text-align: left;
}

.feature-section-second {
  overflow: hidden;
  height: auto;
  max-height: none;
  max-width: 100%;
  min-height: 0%;
  padding-top: 100px;
  padding-bottom: 100px;
}

.slider-section {
  overflow: hidden;
  padding-top: 20px;
  padding-bottom: 180px;
}

.slider {
  position: relative;
  z-index: 3;
  max-width: 1200px;
  margin-right: auto;
  margin-bottom: -140px;
  margin-left: auto;
}

.feature-section-rarities {
  height: auto;
  max-height: none;
  max-width: none;
  min-height: 0%;
  padding-top: 100px;
  padding-bottom: 100px;
}

.flex-container-rarities {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 80%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.section-heading-rarities {
  font-family: 'Open Sans', sans-serif;
  color: #fff;
  font-weight: 700;
  text-align: center;
}

.section-text-rarities {
  position: static;
  z-index: 10;
  font-family: 'Open Sans', sans-serif;
  color: #bafce8;
  font-size: 18px;
  text-align: center;
}

.rarity-slide {
  width: 33%;
  max-width: 33%;
  text-align: center;
}

.slide-2 {
  width: 30%;
  max-width: 30%;
}

.rarity-image {
  position: static;
  display: inline-block;
  width: 50%;
  max-width: 50%;
  text-decoration: none;
}

.div-block-rarities {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.slider-2 {
  background-color: transparent;
}

.slide-nav {
  background-color: transparent;
}

.flex-container-roadmap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 80%;
  margin-bottom: 50px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.div-block-roadmap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  max-height: 100%;
  max-width: 50%;
  min-height: 100%;
  min-width: 50%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.div-block-2 {
  width: 20%;
  height: 100%;
  max-height: 100%;
  max-width: 20%;
}

.div-block-3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 12%;
  max-width: 12%;
  margin-right: 30px;
  margin-bottom: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.div-block-4 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 15%;
  max-width: 15%;
  margin-bottom: 0px;
  margin-left: 0%;
  padding-right: 0px;
  padding-left: 0px;
}

.image-2 {
  width: 100%;
  max-width: 100%;
  margin-right: 0px;
  padding-right: 0px;
}

.roadmap-text {
  display: block;
  width: 100%;
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  margin-top: 0px;
  margin-bottom: auto;
  padding-top: 15px;
  padding-bottom: 30px;
  font-family: Merriweather, serif;
  color: #fff;
  font-size: 26px;
  font-weight: 700;
}

.feature-section-team {
  height: auto;
  max-height: none;
  max-width: 100%;
  min-height: 0%;
  padding-top: 100px;
  padding-bottom: 100px;
}

.team-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 25%;
  max-width: 25%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.image-3 {
  width: 150px;
  max-width: 150px;
}

.image-4 {
  width: 150px;
  max-width: 150px;
}

.image-5 {
  width: 150px;
  max-width: 150px;
}

.team-image {
  width: 100%;
  max-width: 100%;
  margin-bottom: 40px;
  border: 1px solid transparent;
  border-radius: 8px;
}

.feature-section-community {
  max-width: 100%;
  min-height: 0%;
  padding-top: 6%;
  padding-bottom: 6%;
  background-color: #7a7a7a;
  background-position: 0px 0px, 0px 0px;
  background-size: cover, auto;
}

.section-heading-community {
  display: block;
  width: 100%;
  max-width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  font-family: 'Open Sans', sans-serif;
  color: #000;
  font-weight: 700;
}

.section-text-community {
  display: block;
  width: 100%;
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  margin-top: 0px;
  margin-bottom: 46px;
  font-family: Merriweather, serif;
  color: #000;
}

.div-block-commuity {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  min-height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.the-story-image-mask-community {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 100%;
  margin-right: 0px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  text-align: right;
}

.div-block-community {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  max-height: 100%;
  max-width: 50%;
  min-height: 100%;
  min-width: 50%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.image-6 {
  width: 25%;
  max-width: 25%;
}

.image-7 {
  position: static;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  overflow: hidden;
  max-height: 80vh;
  max-width: 0%;
}

.div-block-5 {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  height: 90vh;
  max-height: 90vh;
  background-position: 50% 50%;
  background-size: cover;
}

.image-cloud-scroll-left {
  position: absolute;
  left: 13%;
  top: 61%;
  right: auto;
  bottom: 0%;
  overflow: hidden;
  width: 25%;
  max-width: 20%;
}

.image-cloud-scroll-left-three {
  position: absolute;
  left: 17%;
  top: 13%;
  right: auto;
  bottom: 0%;
  overflow: hidden;
  width: 25%;
  max-width: 25%;
  opacity: 1;
}

.image-cloud-scroll-right-one {
  position: absolute;
  left: auto;
  top: 20%;
  right: 30%;
  bottom: 0%;
  overflow: hidden;
  width: 15%;
  max-width: 15%;
}

.image-cloud-scroll-right-two {
  position: absolute;
  left: auto;
  top: 55%;
  right: 22%;
  bottom: 0%;
  overflow: hidden;
  width: 18%;
  max-width: 18%;
}

.image-cloud-scroll-left-two {
  position: absolute;
  left: 25%;
  top: 40%;
  right: auto;
  bottom: 0%;
  overflow: hidden;
  width: 30%;
  max-width: 30%;
}

.div-block-6 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  height: 50vh;
  max-height: 50vh;
  max-width: 50%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-position: 0px 0px;
  background-size: cover;
}

.image-8 {
  width: 80%;
}

.image-9 {
  position: relative;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
}

.image-10 {
  width: 100%;
  max-width: 100%;
}

.community-image {
  width: 85%;
  margin-top: 0px;
  margin-bottom: -160px;
  padding-top: 0px;
  opacity: 1;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: 50% 50%;
  object-position: 50% 50%;
}

.button-2 {
  margin-bottom: 20px;
  padding: 15px 40px;
  border: 3px solid rgba(245,221,81,1);
  border-radius: 8px;
  background-color: #c3b292;
  font-family: Merriweather, serif;
  color: rgba(245,221,81,1);
  font-weight: 600;
  cursor: pointer;
}

.button-2:hover {
  border-color: #fff;
  color: #fff;
}

.team-images {
  position: static;
  z-index: 2;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 100%;
  margin-right: 0px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  text-align: right;
}

.section-text-team-names {
  position: static;
  z-index: 10;
  margin-bottom: 0px;
  font-family: Merriweather, serif;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
}

.image-11 {
  width: 25px;
  max-width: 25px;
}

.button-3 {
  width: 33.75px;
  height: 30px;
  max-height: 30px;
  max-width: 33.75px;
  margin-top: auto;
  background-color: transparent;
  background-position: 0px 0px;
  background-size: cover;
  background-attachment: scroll;
}

.body {
  background-color: ;
}

.story-image-gems {
  display: block;
  width: 40%;
  max-width: 40%;
  margin-top: 0px;
  padding-top: 0px;
}

.div-block-7 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 100%;
  margin-bottom: -90px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.div-block-rarity {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  max-height: 100%;
  max-width: 55%;
  min-height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.feature-image-about {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  height: 100%;
  max-height: 100%;
  max-width: 50%;
  margin-right: 0px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  text-align: right;
}

.div-block-about {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  max-height: 100%;
  max-width: 55%;
  min-height: 100%;
  padding-left: 5%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.section-heading-team {
  padding-bottom: 60px;
  font-family: 'Open Sans', sans-serif;
  color: #fff;
  font-weight: 700;
  text-align: center;
}

.div-block-team {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  height: 100%;
  max-height: 100%;
  max-width: 50%;
  min-height: 100%;
  padding: 10px 0% 10px 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.flex-container-team {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 80%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.rarity-image-mask {
  position: static;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 100%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  text-align: right;
}

.image-12 {
  width: 240px;
  max-width: 240px;
}

.div-block-8 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 100%;
  margin-top: 0px;
  padding-top: 60px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.footer-item-discord {
  width: 50px;
  height: 50px;
  max-height: 50px;
  max-width: 50px;
  margin-right: 10px;
  margin-left: 10px;
  background-image: url('../../../assets/1x/FOOTER/Asset 38.png') !important;
  background-position: 50% 50%;
  background-size: 46px;
  background-repeat: no-repeat;
  background-attachment: scroll;
  opacity: 1;
  color: #fff;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 1px;
  text-decoration: none;
  text-transform: uppercase;
}

.footer-item-discord:hover {
  opacity: 0.8;
}

.footer-item-discord:active {
  opacity: 0.8;
}

.footer-item-insta {
  width: 50px;
  height: 50px;
  max-height: 50px;
  max-width: 50px;
  margin-right: 10px;
  margin-left: 10px;
  background-image: url('../../../assets/1x/FOOTER/Asset 37.png') !important;
  background-position: 50% 50%;
  background-size: 46px;
  background-repeat: no-repeat;
  background-attachment: scroll;
  opacity: 1;
  color: #fff;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 1px;
  text-decoration: none;
  text-transform: uppercase;
}

.footer-item-insta:hover {
  opacity: 0.8;
}

.footer-item-insta:active {
  opacity: 0.8;
}

.flex-container-roadmap-item-1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 80%;
  margin-top: 45px;
  margin-bottom: 0px;
  padding: 0px 25px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-radius: 8px;
}

.div-block-roadmap-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 70%;
  margin-bottom: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.image-roadmap-side {
  position: relative;
  overflow: visible;
  width: 323px;
  max-width: 323px;
  margin-top: 60%;
  margin-right: 0px;
  margin-left: 50%;
  padding-right: 0px;
  padding-left: 0px;
}

.image-roadmap-side-2 {
  position: relative;
  width: 323px;
  max-width: 323px;
  margin-top: 0%;
  margin-right: 0px;
  margin-left: 50%;
  padding-right: 0px;
  padding-left: 0px;
}

.flex-container-roadmap-item-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 80%;
  margin-top: 60px;
  margin-bottom: 0px;
  padding: 40px 25px 55px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-radius: 8px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#f5ecda), to(#b89d71));
  background-image: linear-gradient(180deg, #f5ecda, #b89d71);
}

.div-block-image-roadmap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0px;
  margin-left: 0%;
  padding-right: 0px;
  padding-left: 0px;
}

.image-roadmap-side-3 {
  position: relative;
  width: 323px;
  max-width: 323px;
  margin-top: 30%;
  margin-right: 0px;
  margin-left: 50%;
  padding-right: 0px;
  padding-left: 0px;
}

.section-text-roadmap {
  display: block;
  width: 100%;
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  margin-top: 0px;
  margin-bottom: auto;
  font-family: Merriweather, serif;
  color: #fff;
  font-size: 18px;
  text-align: left;
}

.flex-container-roadmap-item-3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 80%;
  margin-top: 60px;
  margin-bottom: 0px;
  padding: 40px 25px 55px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-radius: 8px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#f5ecda), to(#b89d71));
  background-image: linear-gradient(180deg, #f5ecda, #b89d71);
}

.section-text-team {
  position: static;
  z-index: 10;
  padding-right: 15%;
  padding-left: 15%;
  font-family: 'Open Sans', sans-serif;
  color: #bafce8;
  font-size: 18px;
  text-align: center;
}

.image-13 {
  position: static;
  z-index: 1;
  width: 50%;
  max-width: 50%;
  margin-top: 0px;
  margin-bottom: 0px;
}

.section-text-team-title {
  position: static;
  z-index: 10;
  margin-bottom: 0px;
  font-family: Merriweather, serif;
  color: #88e9cb;
  font-size: 20px;
  font-weight: 700;
  text-align: left;
}

.div-block-9 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  max-height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  text-align: left;
}

.section-text-team-desc {
  position: static;
  z-index: 10;
  margin-bottom: 0px;
  padding-top: 15px;
  padding-bottom: 15px;
  font-family: Merriweather, serif;
  color: #fff;
  font-size: 16px;
  font-weight: 300;
  text-align: left;
}

.communty-header {
  display: block;
  width: 100%;
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  margin-top: 0px;
  margin-bottom: 46px;
  font-family: 'Open Sans', sans-serif;
  color: #000;
  font-size: 36px;
  font-weight: 700;
}

.image-14 {
  width: 35%;
  max-width: 35%;
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: Merriweather, serif;
}

.image-15 {
  width: 50%;
  max-width: 50%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.flex-container-second {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 80%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.div-block-10 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.nav-menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.nav-link {
  padding-top: 26px;
  padding-bottom: 26px;
  background-color: #1f1f1f;
  font-family: Lato, sans-serif;
  color: #fff;
  font-weight: 700;
  letter-spacing: 0.3px;
  cursor: pointer;
}

.nav-link:hover {
  background-color: #3a3a3a;
}

.clone {
  margin-right: 0px;
  padding: 25px 30px;
  border-radius: 0px;
  background-color: #3545ee;
  -webkit-transition: background-color 400ms ease-in-out, -webkit-transform 300ms ease-in-out;
  transition: background-color 400ms ease-in-out, -webkit-transform 300ms ease-in-out;
  transition: transform 300ms ease-in-out, background-color 400ms ease-in-out;
  transition: transform 300ms ease-in-out, background-color 400ms ease-in-out, -webkit-transform 300ms ease-in-out;
  color: #fff;
  font-size: 18px;
  font-weight: 400;
}

.clone:hover {
  background-color: #4253ff;
  color: #fff;
}

.section-text-footer {
  position: static;
  z-index: 10;
  margin-bottom: 0px;
  padding-top: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
  font-family: 'Open Sans', sans-serif;
  color: rgba(43,22,21,1) !important;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  text-align: left;
  text-transform: uppercase;
}

.div-block-11 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.image-16 {
  width: 30%;
}

.div-block-12 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 100%;
  margin-top: 40px;
  margin-bottom: 40px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.rich-text-block {
  font-family: Merriweather, serif;
  font-size: 18px;
}

.div-block-about-policy {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  min-height: 100%;
  padding-left: 0%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.flex-container-policy {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 80%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.logo-link-privacy {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  margin-top: 20px;
  margin-right: 50%;
  padding-left: 20px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.logo-link-privacy:hover {
  opacity: 0.8;
}

.logo-link-privacy:active {
  opacity: 0.7;
}

.logo-link-privacy.w--current {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10%;
  padding-right: 0%;
  padding-left: 0%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.image-6-privacy {
  width: 20%;
  max-width: 20%;
}

.feature-section-third {
  height: auto;
  max-height: none;
  max-width: 100%;
  min-height: 0%;
  padding-top: 50px;
  padding-bottom: 50px;
  background-position: 50% 50%;
  background-size: auto 100%;
}

.flex-container-third {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 100%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.quest-mask {
  position: static;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 100%;
  margin-right: 0%;
  margin-bottom: -121px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: right;
}

.quest-mask-2 {
  position: static;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 100%;
  margin-right: 0%;
  margin-bottom: -121px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: right;
}

.section-text-first {
  display: block;
  width: 100%;
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  margin-top: 0px;
  margin-bottom: auto;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  font-family: Merriweather, serif;
  color: #fff;
  font-size: 18px;
  font-weight: 300;
  text-align: left;
}

.feature-section-first {
  height: auto;
  max-height: none;
  max-width: 100%;
  min-height: 0%;
  margin-top: 0vh;
  padding-top: 150px;
  padding-bottom: 100px;
}

.feature-section-fourth {
  overflow: hidden;
  height: auto;
  max-height: none;
  max-width: 100%;
  min-height: 0%;
  padding-top: 140px;
  padding-bottom: 100px;
}

.fifth-section-image {
  width: 90%;
  opacity: 1;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: 50% 50%;
  object-position: 50% 50%;
}

.rarity-mask {
  position: static;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 100%;
  margin-right: auto;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  text-align: right;
}

.div-block-rare {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  max-height: 100%;
  max-width: 50%;
  min-height: 100%;
  padding-left: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.image-rarity {
  width: 40%;
  max-width: 40%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.div-block-quest {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  max-height: 100%;
  max-width: 50%;
  min-height: 100%;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.section-text-quest {
  display: block;
  width: 100%;
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  margin-top: 0px;
  margin-bottom: auto;
  padding-bottom: 5%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  font-family: Merriweather, serif;
  color: #fff;
  font-size: 18px;
  font-weight: 300;
  text-align: center;
}

.story-image-gems-2 {
  display: block;
  width: 40%;
  max-width: 40%;
  margin-top: 0px;
  padding-top: 0px;
}

.feature-section-faq {
  height: auto;
  max-height: none;
  max-width: 100%;
  min-height: 0%;
  padding-top: 40px;
  padding-bottom: 0px;
}

.flex-container-faq-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 100%;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px 25px 0px 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-radius: 8px;
}

.dropdown-toggle {
  position: relative;
  display: block;
  padding-bottom: 20px;
  padding-left: 0px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  font-family: Merriweather, serif;
}

.dropdown-toggle.w--open {
  width: 100%;
  max-width: 100%;
}

.dropdown {
  width: 100%;
  max-width: 100%;
  margin-right: 0px;
  margin-left: 0px;
}

.image-faq {
  width: 35%;
  max-width: 35%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.dropdown-link {
  width: 100%;
  max-width: 100%;
  min-width: 100%;
}

.text-block {
  position: static;
  width: 100%;
  max-width: 100%;
  background-color: transparent;
  font-family: Merriweather, serif;
  color: #fff;
  font-weight: 300;
  line-height: 26px;
}

.dropdown-list {
  position: static;
}

.dropdown-list.w--open {
  background-color: transparent;
}

.text-block-2 {
  overflow: visible;
  color: #c3b292;
  font-size: 20px;
  font-weight: 700;
}

.link {
  color: #7be5c4;
}

.link:hover {
  color: #c3b292;
}

.link-2 {
  color: #829bff;
}

.link-3 {
  color: #8099ff;
}

.bold-text {
  font-family: Merriweather, serif;
  font-weight: 700;
}

.first-section-image-story {
  width: 100%;
  max-width: 100%;
  margin-top: 0px;
  margin-right: auto;
  margin-bottom: 0px;
  padding-right: 0%;
  opacity: 1;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: 50% 50%;
  object-position: 50% 50%;
}

.link-4 {
  color: #7be5c4;
}

.first-section-image-2 {
  width: 100%;
  max-width: 100%;
  margin-top: 0px;
  margin-bottom: 0px;
  opacity: 1;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: 50% 50%;
  object-position: 50% 50%;
}

.div-block-13 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 80%;
  max-width: 80%;
  margin-right: 10%;
  margin-left: 10%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.image-17 {
  display: block;
  width: 45%;
  height: 100%;
  max-height: 100%;
  max-width: 45%;
  margin-right: -10%;
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
}

.div-block-14 {
  width: 50%;
  max-width: 50%;
}

.bold-text-4 {
  max-width: 100%;
}

.div-block-15 {
  display: block;
  width: 60%;
  max-width: 60%;
}

.div-block-5-mint {
  position: static;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  width: 100%;
  height: auto;
}

.div-block-mint {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  min-height: 100%;
  padding-right: 0%;
  padding-left: 0%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.section-text-first-copy {
  display: block;
  width: 100%;
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  margin-top: 0px;
  margin-bottom: auto;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  font-family: Merriweather, serif;
  color: #fff;
  font-size: 18px;
  font-weight: 300;
  text-align: left;
}

.button-2-copy {
  margin-bottom: 20px;
  padding: 15px 40px;
  border: 3px solid rgba(245,221,81,1);
  border-radius: 8px;
  background-color: #c3b292;
  font-family: Merriweather, serif;
  color: rgba(245,221,81,1);
  font-weight: 600;
  cursor: pointer;
}

.button-2-copy:hover {
  border-color: #fff;
  color: #fff;
}

.section-text-first-mint {
  display: block;
  width: 100%;
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  margin-top: 0px;
  margin-bottom: auto;
  padding-bottom: 15px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  font-family: Merriweather, serif;
  color: rgba(245,221,81,1);
  font-size: 18px;
  font-weight: 300;
  text-align: left;
}

.button-mint {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 150px;
  height: 50px;
  max-width: 150px;
  margin-bottom: 0px;
  padding: 0px 40px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 3px solid #fff;
  border-radius: 8px;
  background-color: #fff;
  font-family: Merriweather, serif;
  color: rgba(245,221,81,1);
  font-weight: 600;
  text-align: center;
  cursor: pointer;
}

.button-mint:hover {
  border-color: #000;
  color: #000;
}

.feature-section-community-copy {
  max-width: 100%;
  min-height: 0%;
  margin-top: 0vh;
  padding-top: 0%;
  padding-bottom: 6%;
  background-position: 50% 50%;
  background-size: 100%;
  background-repeat: no-repeat;
  text-align: left;
}

.flex-container-copy {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 80%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.flex-container-roadmap-mint {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 80%;
  margin-bottom: 50px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.mintfield {
  width: 250px;
  height: 50px;
  margin-top: 0px;
  margin-bottom: 0px;
  border-style: solid;
  border-width: 3px;
  border-color: #727171;
  border-radius: 8px;
  background-color: rgba(245,221,81,1);
  color: #fff;
}

.form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.form-block {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}

.bold-text-6 {
  color: rgba(245,221,81,1);
  font-weight: 400;
  text-align: center;
}

.section-text-first-mint-head {
  display: block;
  width: 100%;
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  margin-top: 0px;
  margin-bottom: auto;
  padding-bottom: 15px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  font-family: Merriweather, serif;
  color: #fff;
  font-size: 18px;
  font-weight: 300;
  text-align: center;
}

.bold-text-6-mint {
  font-size: 24px;
  text-align: center;
}

.image-18 {
  width: 25%;
  text-align: center;
}

.div-block-16 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 200%;
  max-height: 200%;
  padding-top: 0px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.body-mint {
  overflow: auto;
  background-color: rgba(245,221,81,1);
}

.image-19 {
  width: 20%;
}

.navigation-mint {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 30px 50px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;
}

.navigation-items-mint {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.logo-link-privacy-copy {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 197px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.logo-link-privacy-copy:hover {
  opacity: 0.8;
}

.logo-link-privacy-copy:active {
  opacity: 0.7;
}

.logo-link-privacy-copy.w--current {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10%;
  padding-right: 0%;
  padding-left: 0%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.image-19-copy {
  width: 20%;
}

.navigation-wrap-copy {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: -20px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.sticky-nav {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 1;
  padding: 20px;
  background-color: #f4f4f4;
}

.nav-grid {
  display: -ms-grid;
  display: grid;
  width: 100%;
  margin: 0px;
  padding: 0px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-flow: column;
  grid-auto-columns: -webkit-max-content;
  grid-auto-columns: max-content;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  -ms-grid-columns: 1fr -webkit-max-content -webkit-max-content -webkit-max-content;
  -ms-grid-columns: 1fr max-content max-content max-content;
  grid-template-columns: 1fr -webkit-max-content -webkit-max-content -webkit-max-content;
  grid-template-columns: 1fr max-content max-content max-content;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.nav-logo-link {
  display: block;
  height: 60px;
  margin-right: 0px;
  margin-left: 0px;
  padding: 0px;
}

.nav-logo {
  width: auto;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: 0% 50%;
  object-position: 0% 50%;
}

.nav-link-2 {
  display: block;
  margin-right: 0px;
  margin-left: 0px;
  padding: 10px 0px;
  color: #444;
  text-decoration: none;
}

.nav-link-2:hover {
  text-decoration: underline;
}

.image-20 {
  width: 20%;
}

.minttext {
  color: #fff;
  text-align: left;
}

.div-block-17 {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
}

.bold-text-7 {
  color: #fff;
  font-size: 18px;
}

.clocknumber {
  height: 100%;
  max-height: 100%;
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 64px;
  line-height: 64px;
  font-weight: 800;
}

.clocklabel {
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
}

.block-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 200%;
  max-height: 200%;
  padding-top: 0px;
  padding-right: 15px;
  padding-left: 15px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.div-block-16-timer {
  position: static;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 200%;
  max-height: 200%;
  padding-top: 0px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.div-block-16-timer-mainpage {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 200%;
  max-height: 200%;
  margin-bottom: 0px;
  padding-top: 20px;
  padding-bottom: 30px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.clocklabelmain {
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
}

.clocknumbermain {
  height: 100%;
  max-height: 100%;
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 64px;
  line-height: 64px;
  font-weight: 800;
}

.button-to-mint {
  margin-bottom: 0px;
  padding: 15px 40px;
  border: 3px solid rgba(245,221,81,1);
  border-radius: 8px;
  background-color: #c3b292;
  font-family: Merriweather, serif;
  color: rgba(245,221,81,1);
  font-weight: 600;
  cursor: pointer;
}

.button-to-mint:hover {
  border-color: #fff;
  color: #fff;
}

.html-embed {
  margin-left: 0px;
  padding: 8px 12px;
  color: #fff;
}

.mintbuttonsparent {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 2px 0px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;
}

.mintbutton {
  width: 80px;
  height: 80px;
  margin-right: 20px;
  margin-left: 20px;
  border-radius: 8px;
  background-color: transparent;
  background-position: 50% 50%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 64px;
  line-height: 24px;
  font-weight: 600;
}

.mintbutton:hover {
  background-color: rgba(245,221,81,1);
  color: #7be5c4;
}

.mintbutton:active {
  border-color: #000;
  background-color: transparent;
  color: #fff;
}

.radio-button {
  width: 30px;
  height: 30px;
  margin-top: 0px;
  margin-left: 0px;
  border: 10px solid #000;
  border-radius: 4px;
  outline-color: #1a1b1f;
  outline-offset: 0px;
  outline-style: none;
  outline-width: 3px;
}

.text-block-3 {
  color: #fff;
  font-size: 142px;
  line-height: 150px;
  font-weight: 700;
}

.text-block-4 {
  color: #fff;
  font-size: 18px;
}

.mintbuttonplus {
  width: 80px;
  height: 80px;
  margin-right: 20px;
  margin-left: 20px;
  border-radius: 8px;
  background-color: transparent;
  background-position: 50% 50%;
  background-size: 100%;
  background-repeat: no-repeat;
  background-attachment: scroll;
  color: #fff;
  font-size: 64px;
  line-height: 30px;
  font-weight: 600;
}

.mintbuttonplus:hover {
  background-color: rgba(245,221,81,1);
  color: #7be5c4;
}

.mintbuttonplus:active {
  border-color: #000;
  background-color: transparent;
  color: #fff;
}

.div-block-16-copy {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 200%;
  max-height: 200%;
  margin-bottom: 60px;
  padding-top: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.logo-link-privacy-main {
  position: static;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 20%;
  margin-top: 20px;
  margin-right: 0%;
  padding-left: 0px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.logo-link-privacy-main:hover {
  opacity: 0.8;
}

.logo-link-privacy-main:active {
  opacity: 0.7;
}

.logo-link-privacy-main.w--current {
  position: static;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0vh;
  margin-right: 0%;
  margin-left: 0px;
  padding-right: 0%;
  padding-left: 0%;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.image-20-main {
  height: 100%;
}

.text-block-5 {
  margin-bottom: 30px;
  color: #fff;
  font-size: 42px;
  line-height: 42px;
  font-weight: 700;
}

.div-block-18 {
  padding-bottom: 100px;
}

.text-block-5-copy {
  margin-bottom: 0px;
  color: #fff;
  font-size: 42px;
  line-height: 42px;
  font-weight: 700;
}

.footer-item-os {
  display: block;
  width: 50px;
  height: 50px;
  max-height: 50px;
  max-width: 50px;
  margin-right: 10px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  background-image: url('../../../assets/1x/FOOTER/Asset 40.png') !important;
  background-position: 50% 50%;
  background-size: 45px;
  background-repeat: no-repeat;
  opacity: 1;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.footer-item-os:hover {
  opacity: 0.8;
}

.footer-item-os:active {
  opacity: 0.8;
}

.feature-section-staking {
  height: auto;
  max-height: none;
  max-width: 100%;
  min-height: 0%;
  margin-top: 0vh;
  padding-top: 10%;
  padding-bottom: 10%;
}

.flex-container-staking {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 80%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.feature-container-staking-tab {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  margin-right: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 3px solid #fff;
  border-radius: 3px;
  text-align: right;
}

.staked-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 30%;
  margin-left: 0%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 2px solid #fff;
  border-radius: 0px 8px 8px 0px;
  background-color: transparent;
  font-family: Merriweather, serif;
  color: #fff;
  font-size: 22px;
  font-weight: 700;
}

.staked-button:hover {
  background-color: #fff;
  color: #3f3d3d;
}

.staked-button.w--current {
  padding-bottom: 9px;
  background-color: #fff;
  color: rgba(245,221,81,1);
}

.div-block-staking-info {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  min-height: 100%;
  padding-bottom: 10%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.staking-text-image {
  width: 100%;
  max-width: 40%;
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: Merriweather, serif;
}

.staking-header {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.staking-text-image-2 {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: 0%;
  width: 20%;
  max-width: 35%;
  margin-top: 0%;
  margin-bottom: 0px;
  padding-top: 0%;
  font-family: Merriweather, serif;
}

.section-text-staking {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  margin-top: 0px;
  margin-bottom: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: Merriweather, serif;
  color: #fff;
  font-size: 18px;
  font-weight: 300;
  text-align: left;
}

.staking-analytics-balance {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  height: 80px;
  padding-right: 20px;
  padding-left: 20px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 2px 2px 1px;
  border-color: #5f5f5f;
}

.staking-analytics-yield {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  height: 80px;
  padding-right: 20px;
  padding-left: 20px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px 2px 2px;
  border-color: #5f5f5f;
}

.text-block-6 {
  color: #fff;
  font-size: 24px;
}

.rich-text-block-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fff;
  font-size: 22px;
}

.yoh-balance {
  font-family: Merriweather, serif;
  color: #fff;
  font-size: 18px;
}

.list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: scroll;
  width: 100%;
  height: 500px;
  max-height: 500px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.headertext {
  color: #fff;
  font-size: 22px;
}

.firstlistblock {
  position: relative;
  overflow: scroll;
  width: 100%;
  height: 500px;
  max-height: 500px;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.secondlistblock {
  width: 8%;
  height: 500px;
  max-height: 500px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.thirdlistblock {
  width: 20%;
  height: 500px;
  max-height: 500px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.text-block-7 {
  margin-bottom: 20px;
  color: #fff;
  font-size: 22px;
  text-align: center;
}

.selectall {
  width: 10%;
  margin-bottom: 0px;
  color: #7be5c4;
  font-size: 16px;
  font-style: normal;
  text-align: left;
  cursor: pointer;
}

.nftitemimage {
  position: static;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 25%;
  height: 100%;
  max-width: 25%;
  margin-top: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  float: none;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.image-21 {
  background-position: 0px 0px;
  background-size: auto;
}

.html-embed-2 {
  position: absolute;
  width: 10%;
  height: 10px;
}

.nftitemimage-2 {
  height: 150px;
  border-radius: 0px;
  background-position: 50% 35%;
  background-size: 90%;
  background-repeat: no-repeat;
  background-attachment: scroll;
}

.nftitemimage-3 {
  height: 150px;
  border-top: 2px solid #5f5f5f;
  border-bottom: 0px solid #5f5f5f;
  border-radius: 0px;
  background-position: 50% 35%;
  background-size: 90%;
  background-repeat: no-repeat;
  background-attachment: scroll;
}

.nftitemimage-4 {
  height: 150px;
  border-top: 2px solid #5f5f5f;
  border-bottom: 0px solid #5f5f5f;
  border-radius: 0px;
  background-position: 50% 35%;
  background-size: 90%;
  background-repeat: no-repeat;
  background-attachment: scroll;
}

.text-block-7-copy {
  margin-bottom: 20px;
  color: #fff;
  font-size: 22px;
  text-align: left;
}

.headertextdiv {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  border-bottom: 3px solid #fff;
}

.bold-text-8 {
  width: 20%;
  max-width: 20%;
  font-family: Merriweather, serif;
  font-size: 18px;
}

.herotextlist {
  width: 25%;
  margin-bottom: 0px;
  color: #fff;
  font-size: 18px;
  text-align: center;
}

.yieldtextlist {
  width: 75%;
  margin-bottom: 0px;
  margin-left: 0%;
  padding-left: 5%;
  color: #fff;
  font-size: 18px;
  text-align: left;
}

.nftitemyield {
  position: static;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 75%;
  height: 150px;
  padding-left: 5%;
  float: none;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 0px;
  background-color: transparent;
}

.baseyieldtext {
  width: 100%;
  margin-left: 0%;
  padding-left: 0%;
  color: #fff;
}

.nftcontainer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 150px;
  max-height: 150px;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 0px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 2px solid #5f5f5f;
  background-color: transparent;
  cursor: pointer;
}

.nftcontainer2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 150px;
  max-height: 150px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 2px solid #5f5f5f;
  background-color: rgba(248, 220, 107, 0.6);
}

.yieldtextlist-copy {
  width: 80%;
  margin-bottom: 0px;
  margin-left: 0%;
  padding-left: 5%;
  color: #fff;
  font-size: 22px;
  text-align: left;
}

.bold-text-9 {
  font-family: Merriweather, serif;
}

.bold-text-10 {
  font-family: Merriweather, serif;
}

.list-2 {
  display: block;
  max-width: 80%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.nftimage {
  width: auto;
  height: 90%;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  background-position: 50% 40%;
  background-size: 80%;
  background-repeat: no-repeat;
  background-attachment: scroll;
}

.unstaked-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 30%;
  margin-right: 0%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 2px solid #fff;
  border-radius: 8px 0px 0px 8px;
  background-color: transparent;
  color: #fff;
  font-size: 22px;
}

.unstaked-button:hover {
  background-color: #fff;
  color: #3f3d3d;
}

.unstaked-button.w--current {
  margin-right: 0%;
  border-bottom-width: 2px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 8px;
  background-color: #fff;
  font-family: Merriweather, serif;
  color: rgba(245,221,81,1);
  font-weight: 700;
}

.baseyieldtextselected {
  width: 100%;
  margin-left: 0%;
  padding-left: 0%;
  color: rgba(245,221,81,1);
}

.nftcontainerselected {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 150px;
  max-height: 150px;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 0px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 2px solid #5f5f5f;
  background-color: #7be5c4;
  cursor: pointer;
}

.nftitemselect {
  position: static;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 10%;
  height: 150px;
  padding-left: 0%;
  float: none;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 0px;
  background-color: transparent;
}

.selectnft {
  width: 100%;
  margin-left: 0%;
  padding-left: 0%;
  color: #fff;
  text-align: left;
}

.nftitemunselect {
  position: static;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 10%;
  height: 150px;
  padding-left: 0%;
  float: none;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 0px;
  background-color: transparent;
}

.unselectnft {
  width: 100%;
  margin-left: 0%;
  padding-left: 0%;
  color: rgba(245,221,81,1);
  text-align: left;
}

.div-block-19 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-top: 80px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.stakebutton {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 250px;
  height: 50px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 4px;
  background-color: #7be5c4;
  color: rgba(245,221,81,1);
}

.stakebutton:hover {
  border: 2px solid #fff;
  color: #fff;
}

.tabs {
  width: 100%;
}

.tabs-menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 3px none #fff;
  color: #fff;
}

.text-block-8 {
  color: rgba(245,221,81,1);
}

.herotextliststaked {
  width: 25%;
  margin-bottom: 0px;
  color: #fff;
  font-size: 22px;
  text-align: center;
}

.yieldtextliststaked {
  width: 25%;
  margin-bottom: 0px;
  margin-left: 0%;
  padding-left: 0%;
  color: #fff;
  font-size: 22px;
  text-align: left;
}

.selectallstaked {
  width: 20%;
  margin-bottom: 0px;
  font-family: Merriweather, serif;
  color: #7be5c4;
  font-size: 16px;
  font-style: normal;
  text-align: right;
  cursor: pointer;
}

.daysstaked {
  width: 25%;
  margin-bottom: 0px;
  color: #fff;
  font-size: 22px;
  text-align: left;
}

.bold-text-11 {
  font-family: Merriweather, serif;
  font-size: 18px;
}

.bold-text-12 {
  font-family: Merriweather, serif;
  font-size: 18px;
}

.yieldtextliststakedmulti {
  width: 25%;
  margin-bottom: 0px;
  margin-left: 0%;
  padding-left: 0%;
  color: #fff;
  font-size: 22px;
  text-align: left;
}

.nftcontainerstaked {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 150px;
  max-height: 150px;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 0px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 2px solid #5f5f5f;
  background-color: transparent;
  opacity: 1;
  cursor: pointer;
}

.nftitemimagestaked {
  position: static;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 25%;
  height: 100%;
  max-width: 30%;
  margin-top: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  float: none;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.nftitemyieldstaked {
  position: static;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 25%;
  height: 150px;
  padding-left: 0%;
  float: none;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 0px;
  background-color: transparent;
}

.nftitemselectstaked {
  position: static;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 10%;
  height: 150px;
  padding-left: 0%;
  float: none;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 0px;
  background-color: transparent;
}

.nftimagestaked {
  width: auto;
  height: 90%;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  background-position: 50% 40%;
  background-size: 80%;
  background-repeat: no-repeat;
  background-attachment: scroll;
}

.baseyieldtextstaked {
  width: 100%;
  margin-left: 0%;
  padding-left: 0%;
  font-family: Merriweather, serif;
  color: #fff;
}

.nftitemdaysstaked {
  position: static;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 25%;
  height: 150px;
  padding-left: 0%;
  float: none;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 0px;
  background-color: transparent;
}

.nftitemmultiplierstaked {
  position: static;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 25%;
  height: 150px;
  padding-left: 0%;
  float: none;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 0px;
  background-color: transparent;
}

.unstakebutton {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 250px;
  height: 50px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 2px solid rgba(245,221,81,1);
  border-radius: 4px;
  background-color: #7be5c4;
  color: rgba(245,221,81,1);
}

.unstakebutton:hover {
  border-color: #fff;
  color: #fff;
}

.bold-text-13 {
  font-family: Merriweather, serif;
  font-size: 18px;
}

.nftcontainerstakedselected {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 150px;
  max-height: 150px;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 0px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 2px solid #5f5f5f;
  background-color: #7be5c4;
  cursor: pointer;
}

.basedaystextstaked {
  width: 100%;
  margin-left: 0%;
  padding-left: 0%;
  font-family: Merriweather, serif;
  color: #fff;
}

.basemultipliertextstaked {
  width: 100%;
  margin-left: 0%;
  padding-left: 0%;
  font-family: Merriweather, serif;
  color: #fff;
}

.basemultipliertextstakedselected {
  width: 100%;
  margin-left: 0%;
  padding-left: 0%;
  font-family: Merriweather, serif;
  color: #000;
}

.basedaystextstakedselected {
  width: 100%;
  margin-left: 0%;
  padding-left: 0%;
  font-family: Merriweather, serif;
  color: #000;
}

.baseyieldtextstakedselected {
  width: 100%;
  margin-left: 0%;
  padding-left: 0%;
  font-family: Merriweather, serif;
  color: #000;
}

.nftloading {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 150px;
  max-height: 150px;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 0px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 2px solid #5f5f5f;
  background-color: transparent;
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  cursor: pointer;
}

.loadingball {
  width: 10px;
  height: 10px;
  border: 1px solid #fff;
  border-radius: 20px;
  background-color: #fff;
}

.text-block-9 {
  padding-right: 30px;
  font-family: Merriweather, serif;
}

.image-22 {
  width: 30px;
  height: 30px;
}

.claimbutton {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  height: 30px;
  margin-right: 10px;
  margin-left: 0%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 4px;
  background-color: #7be5c4;
  color: rgba(245,221,81,1);
  font-size: 14px;
}

.claimbutton:hover {
  border: 2px solid #fff;
  color: #fff;
}

.div-block-20 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.bold-text-14 {
  font-family: Merriweather, serif;
}

.image-23 {
  width: auto;
  height: 100%;
}

.selectalldiv {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-top: 0px;
  padding-bottom: 0px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.nftconnectwallet {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 150px;
  max-height: 150px;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 0px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 2px solid #5f5f5f;
  background-color: transparent;
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  cursor: pointer;
}

.text-block-11 {
  font-family: Merriweather, serif;
}

.nftnobalance {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 150px;
  max-height: 150px;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 0px;
  padding-right: 0px;
  padding-left: 10%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-bottom: 2px solid #5f5f5f;
  background-color: transparent;
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  cursor: pointer;
}

.text-block-12 {
  font-family: Merriweather, serif;
  font-size: 16px;
  font-weight: 400;
}

.text-block-13 {
  font-size: 16px;
}

.link-5 {
  font-size: 16px;
}

.link-5-buy {
  font-size: 16px;
}

.link-5-buy:hover {
  color: #fff;
}

.connect_button_main {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 150px;
  height: 50px;
  max-height: 50px;
  margin-right: 0px;
  margin-left: 0px;
  padding: 0px 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 6px;
  background-color: #fff;
  color: #000;
  text-align: center;
}

.connect_button_main:hover {
  background-color: #fff;
  color: #7be5c4;
}

@media screen and (max-width: 700px) {

.div-block-8 {
  flex-direction: column;
}
}

@media screen and (max-width: 991px) {
  .styleguide-block {
    text-align: center;
  }



  .container {
    width: 80%;
    height: 90%;
    max-height: 90%;
    margin-top: 65vh;
  }

  .heading-jumbo {
    font-size: 56px;
    line-height: 70px;
  }

  .first-section.cc-contact {
    padding-right: 0px;
    padding-left: 0px;
  }

  .button {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .logo-link.w--current {
    margin-top: 15%;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .menu-icon {
    display: block;
  }

  .navigation-item {
    padding: 15px 30px;
    -webkit-transition: background-color 400ms ease, opacity 400ms ease, color 400ms ease;
    transition: background-color 400ms ease, opacity 400ms ease, color 400ms ease;
    text-align: center;
  }

  .navigation-item:hover {
    background-color: #f7f8f9;
  }

  .navigation-item:active {
    background-color: #eef0f3;
  }

  .navigation-items {
    background-color: transparent;
  }

  .navigation {
    padding: 40px 30px;
  }

  .menu-button {
    padding: 0px;
  }

  .menu-button.w--open {
    z-index: 11;
    background-color: transparent;
  }

  .navigation-wrap {
    margin-right: 0px;
  }

  .work-experience-grid {
    grid-template-areas: ". ."
      ". .";
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .works-grid {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .carrer-headline-wrap {
    width: auto;
  }

  .work-image {
    margin-bottom: 30px;
  }

  .contact {
    width: auto;
    padding: 30px 50px 40px;
  }

  .contact-headline {
    width: 100%;
  }

  .contact-form-wrap {
    width: 100%;
  }

  .webflow-link {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .footer-links {
    position: relative;
    padding-top: 20px;
    padding-bottom: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .about-intro-grid {
    grid-row-gap: 50px;
    grid-template-areas: "."
      ".";
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto 50px auto;
    grid-template-rows: auto auto;
    text-align: center;
  }

  .about-head-text-wrap {
    width: 80%;
    margin-right: auto;
    margin-left: auto;
  }

  .service-name-text {
    font-size: 24px;
    line-height: 42px;
  }

  .skillset-wrap {
    padding-right: 0px;
  }

  .services-items-grid {
    padding-top: 0px;
    grid-row-gap: 0px;
    grid-template-areas: "."
      ".";
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto 0px auto;
    grid-template-rows: auto auto;
  }

  .skills-grid {
    width: 80%;
    margin-right: auto;
    margin-left: auto;
    grid-row-gap: 50px;
    grid-template-areas: "."
      ".";
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto 50px auto;
    grid-template-rows: auto auto;
    text-align: center;
  }

  .personal-features-grid {
    width: 80%;
    margin-right: auto;
    margin-left: auto;
    grid-template-areas: "."
      "."
      "."
      ".";
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto auto auto;
    grid-template-rows: auto auto auto auto;
    text-align: center;
  }

  .social-media-heading {
    width: 80%;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
  }

  .social-media-grid {
    grid-template-areas: ". ."
      ". ."
      ". ."
      ". .";
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto auto auto;
    grid-template-rows: auto auto auto auto;
  }

  .project-overview-grid {
    width: 70%;
    margin-right: auto;
    margin-left: auto;
    grid-row-gap: 50px;
    grid-template-areas: "."
      "."
      ".";
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto 50px auto 50px auto;
    grid-template-rows: auto auto auto;
    text-align: center;
  }

  .project-description-grid {
    width: 80%;
    margin-right: auto;
    margin-left: auto;
    grid-row-gap: 50px;
    grid-template-areas: "."
      ".";
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto 50px auto;
    grid-template-rows: auto auto;
    text-align: center;
  }

  .email-section {
    margin-bottom: 160px;
  }

  .email-link {
    font-size: 36px;
    line-height: 54px;
  }

  .div-block-3 {
    width: 15%;
  }

  .image-6 {
    width: 35%;
    max-width: 35%;
  }

  .image-cloud-scroll-left {
    max-width: 25%;
  }

  .image-cloud-scroll-left-three {
    left: 12%;
  }

  .image-cloud-scroll-right-one {
    right: 13%;
    width: 17%;
    max-width: 17%;
  }

  .image-cloud-scroll-right-two {
    width: 20%;
    max-width: 20%;
  }

  .image-cloud-scroll-left-two {
    width: 35%;
    max-width: 35%;
  }

  .image-13 {
    width: 80%;
    max-width: 80%;
  }

  .image-14 {
    width: 40%;
    max-width: 40%;
  }

  .image-15 {
    width: 80%;
    max-width: 80%;
  }

  .nav-menu {
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: auto;
    z-index: 10;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 10vh;
    padding-bottom: 10vh;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: rgba(245,221,81,1);
  }

  .nav-link {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .clone {
    display: block;
    font-size: 14px;
  }

  .div-block-11 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .image-16 {
    width: 75%;
  }

  .div-block-12 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .logo-link-privacy.w--current {
    margin-top: 20%;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .image-6-privacy {
    width: 35%;
    max-width: 35%;
  }

  .image-rarity {
    width: 80%;
    max-width: 80%;
  }

  .image-faq {
    width: 80%;
    max-width: 80%;
  }

  .div-block-13 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .image-17 {
    width: 75%;
    max-width: 75%;
    margin-right: auto;
    margin-left: auto;
  }

  .button-mint {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 150px;
    height: 50px;
    max-height: 50px;
    margin-bottom: 30px;
    padding-top: 0px;
    padding-bottom: 0px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .image-18 {
    width: 40%;
  }

  .navigation-mint {
    padding: 40px 30px;
  }

  .navigation-items-mint {
    background-color: transparent;
  }

  .logo-link-privacy-copy.w--current {
    margin-top: 15%;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .navigation-wrap-copy {
    margin-right: 0px;
  }

  .image-20 {
    width: 30%;
  }

  .div-block-17 {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .logo-link-privacy-main {
    padding-left: 0px;
  }

  .logo-link-privacy-main.w--current {
    display: block;
    height: 50%;
    margin-top: 0%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .image-20-main {
    width: 100%;
  }

  .text-block-5 {
    font-size: 35px;
  }

  .text-block-5-copy {
    font-size: 35px;
  }

  .staking-text-image {
    width: 40%;
    max-width: 40%;
  }

  .staking-text-image-2 {
    width: 25%;
    max-width: 25%;
  }

  .staking-analytics-balance {
    width: 80%;
  }

  .staking-analytics-yield {
    width: 80%;
  }

  .thirdlistblock {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .selectall {
    width: 20%;
    color: #7be5c4;
    font-size: 16px;
    text-align: right;
  }

  .nftitemimage {
    width: 30%;
    height: 130px;
    max-width: 50%;
  }

  .nftitemimage-2 {
    height: 120px;
  }

  .nftitemimage-3 {
    height: 120px;
  }

  .nftitemimage-4 {
    height: 120px;
  }

  .herotextlist {
    width: 30%;
  }

  .yieldtextlist {
    width: 50%;
  }

  .nftitemyield {
    width: 50%;
    height: 130px;
  }

  .nftimage {
    width: auto;
    height: 90%;
  }

  .nftitemselect {
    width: 20%;
    height: 130px;
    text-align: left;
  }

  .selectnft {
    text-align: right;
  }

  .nftitemunselect {
    width: 40%;
    height: 130px;
  }

  .unselectnft {
    text-align: right;
  }

  .herotextliststaked {
    width: 25%;
  }

  .yieldtextliststaked {
    width: 25%;
  }

  .selectallstaked {
    width: 20%;
    color: #7be5c4;
    font-size: 16px;
    text-align: right;
  }

  .daysstaked {
    width: 25%;
  }

  .yieldtextliststakedmulti {
    width: 25%;
  }

  .nftitemimagestaked {
    width: 25%;
    height: 130px;
  }

  .nftitemyieldstaked {
    width: 25%;
    height: 130px;
  }

  .nftitemselectstaked {
    width: 40%;
    height: 130px;
  }

  .nftimagestaked {
    width: auto;
  }

  .nftitemdaysstaked {
    width: 25%;
    height: 130px;
  }

  .nftitemmultiplierstaked {
    width: 25%;
    height: 130px;
  }
}

@media screen and (max-width: 767px) {
  .heading-jumbo-small {
    font-size: 30px;
    line-height: 52px;
  }

  .rich-text {
    width: 90%;
    max-width: 470px;
    text-align: left;
  }

  .container {
    height: 85%;
    max-height: 85%;
    margin-top: 75vh;
    text-align: center;
  }

  .heading-jumbo {
    font-size: 50px;
    line-height: 64px;
  }

  .first-section {
    margin-right: 0px;
    margin-left: 0px;
  }

  .first-section.cc-contact {
    padding: 15px;
  }

  .paragraph-bigger {
    font-size: 16px;
    line-height: 28px;
  }

  .logo-link {
    padding-left: 0px;
  }

  .navigation {
    margin-left: 0px;
    padding: 40px 30px;
  }

  .menu-button.w--open {
    z-index: 11;
  }

  .work-experience-grid {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .work-position-wrap {
    margin-bottom: 40px;
  }

  .project-name-link {
    font-size: 16px;
    line-height: 28px;
  }

  .text-field.cc-textarea {
    text-align: left;
  }

  .contact {
    padding-right: 30px;
    padding-left: 30px;
  }

  .contact-form-grid {
    grid-column-gap: 30px;
    grid-template-areas: "."
      "."
      ".";
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto auto;
    grid-template-rows: auto auto auto;
  }

  .contact-form {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .contact-form-wrap {
    text-align: left;
  }

  .footer-wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;
  }

  .webflow-link {
    margin-bottom: 15px;
  }

  .footer-links {
    padding-top: 20px;
    padding-bottom: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    flex-direction: row;
  }

  .footer-item {
    margin: 10px;
  }

  .about-head-text-wrap {
    width: 70%;
    max-width: 470px;
  }

  .skills-grid {
    width: 70%;
    max-width: 470px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .personal-features-grid {
    width: 70%;
    max-width: 470px;
  }

  .social-media-heading {
    width: 70%;
    max-width: 470px;
  }

  .social-media-grid {
    grid-column-gap: 15px;
    grid-row-gap: 15px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .project-overview-grid {
    width: 80%;
    max-width: 470px;
    margin-top: 90px;
    margin-bottom: 95px;
  }

  .project-description-grid {
    width: 70%;
    max-width: 470px;
    margin-top: 90px;
    margin-bottom: 85px;
  }

  .detail-image {
    margin-bottom: 15px;
  }

  .email-section {
    width: 80%;
    max-width: 470px;
    margin-top: 120px;
    margin-bottom: 120px;
  }

  .email-link {
    font-size: 36px;
    line-height: 54px;
  }

  .utility-page-wrap {
    padding: 15px;
  }

  ._404-wrap {
    padding: 30px;
  }

  .connect_button {
    margin-right: 0px;
    margin-left: 0px;
  }

  .feature-section-roadmap {
    padding: 40px 20px;
  }

  .flex-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .feature-image-mask {
    width: 100%;
    max-width: 100%;
    margin-right: 0px;
  }

  .first-section-image {
    width: 75%;
    max-width: 75%;
    margin-right: auto;
    margin-left: auto;
  }

  .div-block {
    max-width: 100%;
  }

  .the-story-image-mask {
    width: 100%;
    max-width: 100%;
    margin-right: 0px;
  }

  .feature-section-second {
    padding: 40px 20px;
  }

  .feature-section-rarities {
    padding: 40px 20px;
  }

  .flex-container-rarities {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .flex-container-roadmap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .div-block-roadmap {
    max-width: 100%;
  }

  .div-block-3 {
    width: 50%;
    max-width: 50%;
    margin-right: 0px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .div-block-4 {
    width: 100%;
    max-width: 100%;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .image-2 {
    margin-right: 0px;
  }

  .roadmap-text {
    text-align: center;
  }

  .feature-section-team {
    padding: 40px 20px;
  }

  .team-div {
    width: 100%;
    max-width: 100%;
    margin-bottom: 30px;
  }

  .team-image {
    width: 60%;
    max-width: 60%;
  }

  .feature-section-community {
    padding: 40px 20px;
  }

  .div-block-commuity {
    max-width: 100%;
  }

  .the-story-image-mask-community {
    width: 100%;
    max-width: 100%;
    margin-right: 0px;
  }

  .div-block-community {
    max-width: 100%;
  }

  .image-6 {
    width: 40%;
    max-width: 40%;
  }

  .community-image {
    margin: 40px auto 0px;
  }

  .team-images {
    width: 100%;
    max-width: 100%;
    margin-right: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .story-image-gems {
    width: 30%;
    max-width: 30%;
  }

  .div-block-7 {
    margin-bottom: 0px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .div-block-rarity {
    max-width: 100%;
  }

  .feature-image-about {
    width: 100%;
    max-width: 100%;
    margin-right: 0px;
  }

  .div-block-about {
    max-width: 100%;
  }

  .div-block-team {
    width: 100%;
    max-width: 100%;
  }

  .flex-container-team {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .rarity-image-mask {
    width: 100%;
    max-width: 100%;
    margin-right: 0px;
  }

  .footer-item-discord {
    margin: 10px;
  }

  .flex-container-roadmap-item-1 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .div-block-roadmap-item {
    max-width: 80%;
  }

  .image-roadmap-side {
    margin-top: 10%;
    margin-left: 0%;
  }

  .image-roadmap-side-2 {
    margin-top: 10%;
    margin-left: 0%;
  }

  .flex-container-roadmap-item-2 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .image-roadmap-side-3 {
    margin-top: 10%;
    margin-left: 0%;
  }

  .section-text-roadmap {
    text-align: center;
  }

  .flex-container-roadmap-item-3 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .image-15 {
    width: 75%;
    max-width: 80%;
  }

  .flex-container-second {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }

  .div-block-10 {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
  }

  .nav-menu {
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: auto;
    z-index: 10;
    padding-top: 10vh;
    padding-bottom: 10vh;
    background-color: rgba(245,221,81,1);
  }

  .nav-link {
    width: 100%;
  }

  .div-block-about-policy {
    max-width: 100%;
  }

  .flex-container-policy {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .logo-link-privacy {
    padding-left: 0px;
  }

  .logo-link-privacy.w--current {
    margin-top: 25%;
  }

  .image-6-privacy {
    width: 40%;
    max-width: 40%;
  }

  .feature-section-third {
    padding: 40px 20px;
  }

  .flex-container-third {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }

  .quest-mask {
    width: 100%;
    max-width: 100%;
    margin-right: 0px;
    margin-bottom: 0px;
  }

  .quest-mask-2 {
    width: 100%;
    max-width: 100%;
    margin-right: 0px;
    margin-bottom: 0px;
  }

  .feature-section-first {
    padding: 80px 20px 40px;
  }

  .feature-section-fourth {
    padding: 40px 20px;
  }

  .fifth-section-image {
    margin-right: auto;
    margin-left: auto;
  }

  .rarity-mask {
    width: 100%;
    max-width: 100%;
    margin-right: 0px;
  }

  .div-block-rare {
    max-width: 100%;
  }

  .image-rarity {
    width: 45%;
    max-width: 50%;
  }

  .div-block-quest {
    max-width: 100%;
  }

  .story-image-gems-2 {
    width: 40%;
    max-width: 40%;
  }

  .feature-section-faq {
    padding: 40px 20px 0px;
  }

  .flex-container-faq-item {
    padding-right: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .dropdown-toggle {
    width: 100%;
    max-width: 100%;
    white-space: pre-wrap;
  }

  .image-faq {
    width: 65%;
    max-width: 80%;
  }

  .bold-text {
    display: inline;
    max-width: 100%;
  }

  .bold-text-2 {
    display: block;
    width: 40%;
    max-width: none;
  }

  .bold-text-3 {
    background-clip: content-box;
    -webkit-text-fill-color: inherit;
  }

  .first-section-image-story {
    width: 100%;
    max-width: 100%;
    margin-right: auto;
    margin-left: auto;
  }

  .first-section-image-2 {
    width: 75%;
    max-width: 75%;
    margin-right: auto;
    margin-left: auto;
  }

  .div-block-15 {
    width: 100%;
    max-width: 100%;
  }

  .div-block-mint {
    max-width: 100%;
  }

  .button-mint {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 150px;
    height: 50px;
    max-height: 50px;
    padding: 0px 15px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .feature-section-community-copy {
    padding: 40px 20px;
  }

  .flex-container-copy {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .flex-container-roadmap-mint {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .image-18 {
    width: 55%;
  }

  .navigation-mint {
    padding: 40px 30px;
  }

  .logo-link-privacy-copy {
    padding-left: 0px;
  }

  .sticky-nav {
    position: relative;
  }

  .nav-grid {
    text-align: center;
  }

  .image-20 {
    width: 40%;
  }

  .logo-link-privacy-main {
    padding-left: 0px;
  }

  .logo-link-privacy-main.w--current {
    margin-top: 0%;
  }

  .image-20-main {
    width: 100%;
  }

  .text-block-5 {
    font-size: 32px;
  }

  .text-block-5-copy {
    font-size: 32px;
  }

  .footer-item-os {
    margin: 10px;
  }

  .feature-section-staking {
    padding: 80px 20px 40px;
  }

  .flex-container-staking {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .feature-container-staking-tab {
    width: 100%;
    max-width: 100%;
    margin-right: 0px;
  }

  .staked-button {
    width: 40%;
  }

  .div-block-staking-info {
    max-width: 100%;
  }

  .staking-analytics-balance {
    width: 100%;
  }

  .staking-analytics-yield {
    width: 100%;
  }

  .yoh-balance {
    font-size: 18px;
  }

  .selectall {
    width: 25%;
  }

  .nftitemimage {
    width: 40%;
    height: 100%;
    max-height: 100%;
  }

  .herotextlist {
    width: 40%;
  }

  .yieldtextlist {
    width: 60%;
  }

  .nftitemyield {
    width: 60%;
  }

  .bold-text-9 {
    font-size: 18px;
  }

  .bold-text-10 {
    font-size: 18px;
  }

  .nftimage {
    width: auto;
    max-height: 100%;
  }

  .unstaked-button {
    width: 40%;
  }

  .nftitemselect {
    width: 20%;
  }

  .nftitemunselect {
    width: 32%;
  }

  .herotextliststaked {
    width: 25%;
  }

  .yieldtextliststaked {
    width: 25%;
    text-align: left;
  }

  .selectallstaked {
    width: 30%;
    text-align: right;
  }

  .daysstaked {
    width: 25%;
  }

  .bold-text-12 {
    text-align: left;
  }

  .yieldtextliststakedmulti {
    width: 25%;
    text-align: center;
  }

  .nftitemimagestaked {
    width: 25%;
    height: 100%;
    max-height: 100%;
  }

  .nftitemyieldstaked {
    width: 25%;
  }

  .nftitemselectstaked {
    width: 32%;
  }

  .nftimagestaked {
    width: auto;
    height: 80%;
    max-height: 80%;
  }

  .nftitemdaysstaked {
    width: 25%;
  }

  .nftitemmultiplierstaked {
    width: 25%;
    text-align: center;
  }

  .bold-text-14 {
    font-size: 18px;
  }

  .connect_button_main {
    margin-right: 0px;
    margin-left: 0px;
  }
}

@media screen and (max-width: 479px) {
  .rich-text {
    width: 100%;
    max-width: none;
  }

  .container {
    width: 100%;
    height: auto;
    max-height: 95%;
    max-width: 100%;
    margin-top: 40vh;
    margin-right: 0px;
    margin-left: 0px;
  }

  .heading-jumbo {
    font-size: 36px;
    line-height: 48px;
  }

  .first-section {
    height: 100vh;
    max-height: 100vh;
    margin-right: 0px;
    margin-left: 0px;
  }

  .logo-link.w--current {
    height: 100%;
    max-height: 100%;
    margin-top: 40%;
  }

  .menu-icon {
    position: relative;
    z-index: 2;
    color: #fff;
  }

  .navigation-items {
    height: 100px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .navigation {
    margin-left: 0px;
    padding-right: 20px;
    padding-left: 20px;
  }

  .menu-button {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .menu-button.w--open {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .contact {
    padding-right: 15px;
    padding-left: 15px;
  }

  .contact-form {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .contact-form-wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .footer-wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .footer-links {
    position: static;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    z-index: 1;
    margin-top: auto;
    padding-top: 20px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .about-head-text-wrap {
    width: 100%;
    max-width: none;
  }

  .skills-grid {
    width: 100%;
    max-width: none;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .personal-features-grid {
    width: 100%;
    max-width: none;
  }

  .social-media-heading {
    width: 100%;
    max-width: none;
  }

  .project-overview-grid {
    width: 100%;
    max-width: none;
  }

  .project-description-grid {
    width: 100%;
    max-width: none;
  }

  .email-section {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    max-width: none;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .email-link {
    font-size: 30px;
    line-height: 46px;
  }

  .first-section-image {
    width: 100%;
  }

  .image-2 {
    max-width: 100%;
  }

  .roadmap-text {
    font-size: 22px;
  }

  .team-div {
    width: 100%;
    max-width: 100%;
    margin-bottom: 40px;
  }

  .image-6 {
    width: 75%;
    max-width: 75%;
  }

  .div-block-5 {
    height: 80vh;
    max-height: 80vh;
    margin-top: 0vh;
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: scroll;
  }

  .image-cloud-scroll-left {
    top: 68%;
    width: 45%;
    max-width: 45%;
  }

  .image-cloud-scroll-left-three {
    top: 3%;
    width: 40%;
    max-width: 40%;
  }

  .image-cloud-scroll-right-one {
    top: 31%;
    width: 25%;
    max-width: 25%;
  }

  .image-cloud-scroll-right-two {
    width: 40%;
    max-width: 40%;
  }

  .image-cloud-scroll-left-two {
    width: 70%;
    max-width: 70%;
  }

  .button-2 {
    overflow: visible;
    font-size: 14px;
    -o-object-fit: fill;
    object-fit: fill;
  }

  .team-images {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .flex-container-roadmap-item-1 {
    max-width: 90%;
  }

  .div-block-roadmap-item {
    max-width: 90%;
  }

  .image-roadmap-side {
    width: 100%;
    max-width: 100%;
  }

  .image-roadmap-side-2 {
    width: 100%;
    max-width: 100%;
  }

  .flex-container-roadmap-item-2 {
    max-width: 90%;
  }

  .image-roadmap-side-3 {
    width: 100%;
    max-width: 100%;
  }

  .flex-container-roadmap-item-3 {
    max-width: 90%;
  }

  .image-13 {
    width: 100%;
    max-width: 100%;
  }

  .div-block-10 {
    width: 100%;
    max-width: 100%;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .nav-menu {
    position: absolute;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: auto;
    z-index: 1;
    padding-top: 10vh;
    padding-bottom: 10vh;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .nav-link {
    width: 100%;
  }

  .image-16 {
    width: 100%;
  }

  .logo-link-privacy {
    margin-top: 0px;
    margin-right: 60%;
  }

  .logo-link-privacy.w--current {
    height: 100%;
    max-height: 100%;
    margin-top: 26%;
  }

  .image-6-privacy {
    width: 75%;
    max-width: 75%;
  }

  .fifth-section-image {
    width: 100%;
  }

  .feature-section-faq {
    overflow: hidden;
  }

  .flex-container-faq-item {
    max-width: 90%;
  }

  .dropdown-toggle {
    overflow: visible;
    width: 100%;
    max-width: 100%;
    padding-right: 0px;
  }

  .text-block-2 {
    display: block;
    width: 100%;
    max-width: 100%;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .bold-text {
    width: 100%;
    max-width: 100%;
    font-size: 18px;
    -o-object-fit: fill;
    object-fit: fill;
  }

  .first-section-image-story {
    width: 100%;
    max-width: 100%;
  }

  .first-section-image-2 {
    width: 100%;
  }

  .image-17 {
    width: 100%;
    max-width: 100%;
  }

  .div-block-5-mint {
    height: auto;
    max-height: 100%;
    margin-top: 0vh;
    background-image: none;
  }

  .section-text-first-mint {
    font-size: 16px;
  }

  .button-mint {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 150px;
    height: 50px;
    max-height: 50px;
    padding-top: 0px;
    padding-bottom: 0px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .bold-text-6 {
    color: rgba(245,221,81,1);
  }

  .image-18 {
    width: 75%;
  }

  .navigation-mint {
    padding-right: 20px;
    padding-left: 20px;
  }

  .logo-link-privacy-copy.w--current {
    height: 100%;
    max-height: 100%;
    margin-top: 26%;
  }

  .sticky-nav {
    padding: 0px;
  }

  .nav-grid {
    grid-auto-flow: row;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: 100px;
    grid-template-rows: 100px;
  }

  .nav-logo-link {
    border-top: 1px solid #c4c4c4;
  }

  .nav-link-2 {
    padding-top: 10px;
    padding-bottom: 10px;
    border-top: 1px solid #c4c4c4;
  }

  .image-20 {
    width: 90%;
  }

  .clocknumber {
    font-size: 32px;
  }

  .clocklabel {
    font-size: 16px;
  }

  .clocklabelmain {
    font-size: 18px;
  }

  .clocknumbermain {
    font-size: 42px;
  }

  .button-to-mint {
    overflow: visible;
    font-size: 14px;
    -o-object-fit: fill;
    object-fit: fill;
  }

  .mintbutton {
    width: 60px;
    height: 60px;
  }

  .text-block-3 {
    font-size: 92px;
  }

  .mintbuttonplus {
    width: 60px;
    height: 60px;
  }

  .logo-link-privacy-main {
    position: static;
    width: 40%;
    height: 100%;
    padding-left: 0px;
  }

  .logo-link-privacy-main.w--current {
    height: auto;
    margin-top: 0%;
  }

  .image-20-main {
    width: 100%;
    height: auto;
  }

  .text-block-5 {
    font-size: 22px;
  }

  .text-block-5-copy {
    font-size: 22px;
  }

  .feature-section-staking {
    padding-right: 0px;
    padding-left: 0px;
  }

  .flex-container-staking {
    max-width: 100%;
  }

  .feature-container-staking-tab {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .staked-button {
    width: 50%;
    border-top-left-radius: 0px;
    border-top-right-radius: 8px;
  }

  .staked-button.w--current {
    border-top-right-radius: 8px;
  }

  .div-block-staking-info {
    max-width: 90%;
  }

  .staking-text-image {
    width: 90%;
    max-width: 90%;
  }

  .staking-text-image-2 {
    width: 50%;
    max-width: 50%;
    margin-top: -35%;
    margin-left: 0px;
    padding-left: 0px;
  }

  .text-block-6 {
    font-size: 20px;
  }

  .yoh-balance {
    font-size: 14px;
  }

  .firstlistblock {
    text-align: left;
  }

  .selectall {
    width: 20%;
    text-align: center;
  }

  .nftitemimage {
    width: 30%;
    height: 100%;
    max-height: 100%;
    max-width: 30%;
  }

  .bold-text-8 {
    font-family: Merriweather, serif;
    font-size: 14px;
  }

  .herotextlist {
    width: 30%;
  }

  .yieldtextlist {
    width: 60%;
    padding-left: 0%;
    text-align: center;
  }

  .nftitemyield {
    width: 60%;
    padding-left: 0%;
    font-size: 14px;
    text-align: center;
  }

  .baseyieldtext {
    font-size: 14px;
  }

  .bold-text-9 {
    font-size: 16px;
  }

  .bold-text-10 {
    font-size: 16px;
  }

  .nftimage {
    height: auto;
    max-height: 100%;
    max-width: 100%;
  }

  .unstaked-button {
    width: 50%;
  }

  .unstaked-button.w--current {
    width: 50%;
  }

  .nftitemselect {
    width: 20%;
    font-size: 14px;
  }

  .selectnft {
    font-size: 14px;
  }

  .unselectnft {
    font-size: 14px;
  }

  .tabs {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 95%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .tabs-menu {
    max-width: 90%;
  }

  .herotextliststaked {
    width: 25%;
    font-family: Merriweather, serif;
    font-size: 14px;
  }

  .yieldtextliststaked {
    width: 25%;
    font-size: 14px;
  }

  .selectallstaked {
    width: 30%;
    font-size: 14px;
  }

  .daysstaked {
    width: 25%;
    font-size: 14px;
    text-align: left;
  }

  .bold-text-11 {
    font-family: Merriweather, serif;
    font-size: 14px;
  }

  .bold-text-12 {
    font-family: Merriweather, serif;
    font-size: 14px;
  }

  .yieldtextliststakedmulti {
    width: 25%;
    font-size: 14px;
  }

  .nftcontainerstaked {
    width: 100%;
    padding-right: 0%;
    padding-left: 0%;
  }

  .nftitemimagestaked {
    height: 100%;
    max-height: 1000%;
  }

  .nftimagestaked {
    width: auto;
    height: auto;
    max-height: 100%;
    max-width: 100%;
  }

  .baseyieldtextstaked {
    font-family: Merriweather, serif;
    font-size: 14px;
  }

  .bold-text-13 {
    font-size: 14px;
  }

  .basedaystextstaked {
    font-family: Merriweather, serif;
    font-size: 14px;
  }

  .basemultipliertextstaked {
    font-family: Merriweather, serif;
    font-size: 14px;
  }

  .basemultipliertextstakedselected {
    font-family: Merriweather, serif;
    font-size: 14px;
  }

  .basedaystextstakedselected {
    font-family: Merriweather, serif;
    font-size: 14px;
  }

  .baseyieldtextstakedselected {
    font-family: Merriweather, serif;
    font-size: 14px;
  }

  .claimbutton {
    width: auto;
    margin-right: 0px;
  }

  .div-block-20 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .bold-text-14 {
    font-size: 16px;
  }

  .text-block-10 {
    font-size: 18px;
  }

  .tabs-content {
    width: 100%;
  }

  .nftconnectwallet {
    padding-right: 10%;
    padding-left: 10%;
  }

  .nftnobalance {
    padding-right: 10%;
  }
}
