@import url('https://fonts.googleapis.com/css2?family=Extenda&family=Poppins:wght@400;500;900&display=swap');

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  font-family: 'Poppins', sans-serif;
}

html {
  font-family: 'Poppins', sans-serif;
  overflow-x: hidden;
  line-height: 26px;
}

p {
  line-height: 26px;
}

body {
  position: relative;
  min-height: 100vh;
  background: rgba(255,225,51,1);
  overflow-x: hidden;
}
body::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  z-index: -1;
  opacity: 0.3;
}

button {
  padding: 0;
  cursor: pointer;
}

button,
textarea,
input {
  font-family: 'Poppins', sans-serif;
}

.track-vertical {
  right: 2px;
  display: block !important;
  height: 100%;
  background: #101833;
  border-radius: 8px;
}

.thumb-vertical {
  right: 0;
  display: block !important;
  background: #00C0FF;
  border-radius: 8px;
}
